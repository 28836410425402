import { Col, Row } from "antd";
import React from "react";
import Post from "../Post";
import { map } from "lodash";

const TopPost = ({ primaryPost, sidePosts }) => {
  return (
    <div className='mb-6'>
      <Row gutter={30}>
        <Col span={15}>
          <Post width={"100%"} position='top' post={primaryPost} />
        </Col>
        <Col span={9} className="max-h-[480px] overflow-y-auto posts-sidebar">
          {map(sidePosts, (item, index) => (
            <Post key={index} width={"100%"} position='side' post={item} />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default TopPost;
