import { Typography } from "antd";
import { Footer } from "antd/es/layout/layout";
import React from "react";

const FooterDashboard = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
      }}
    >
      <Typography className='text-sm'>Copyright ©{new Date().getFullYear()} Aushunsine. All rights reserved</Typography>
      <Typography className='text-sm'>Designed and created by KienNH23</Typography>
    </Footer>
  );
};

export default FooterDashboard;
