import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modals",
  initialState: {
    isShowRegisterModal: false,
    isShowLoginModal: false,
    isEditor: false,
  },
  reducers: {
    setShowRegisterModal: (state, action) => {
      state.isShowRegisterModal = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.isShowLoginModal = action.payload;
    },
    setEditor: (state, action) => {
      state.isEditor = action.payload;
    },
  },
});

export const { setShowRegisterModal, setShowLoginModal, setEditor } = modalSlice.actions;

export default modalSlice.reducer;
