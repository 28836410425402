import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Result, Space, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const DiscountDashboard = () => {
  useEffect(() => {
    document.title = "Chiết khấu sản phẩm";
  }, []);
  return (
    <Space direction='vertical' className='p-3'>
      <Breadcrumb
        items={[
          {
            href: "/dashboard",
            title: <HomeOutlined />,
          },
          {
            href: "/dashboard",
            title: <span>Chiết khấu sản phẩm</span>,
          },
          {
            title: <span>Chi tiết</span>,
          },
        ]}
      />
      <Space direction='vertical'>
        <Typography.Title level={4} className='my-4'>
          Chiết khấu sản phẩm
        </Typography.Title>
      </Space>
      <Content>
        <div
          style={{
            padding: 24,
            minHeight: 360,
            backgroundColor: "white",
            borderRadius: 8,
          }}
        >
          <Result
            status='404'
            title='404'
            subTitle='Chức năng đang được phát triển'
            extra={
              <Link to={"/dashboard"}>
                <Button type='primary'>Về trang chủ</Button>
              </Link>
            }
          />
        </div>
      </Content>
    </Space>
  );
};

export default DiscountDashboard;
