import { Button, Result } from "antd";
import React from "react";

const NotFound = () => {
  return (
    <Result
      status='404'
      title='404'
      subTitle='Xin lỗi, trang bạn tìm kiếm không tồn tại'
      extra={
        <Button type='primary' onClick={() => (window.location.pathname = "/")}>
          Về trang chủ
        </Button>
      }
    />
  );
};

export default NotFound;
