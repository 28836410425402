import { Button, InputNumber, Typography, notification } from "antd";
import React, { useState } from "react";
import { addProduct } from "../../redux/reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import { setShowLoginModal } from "../../redux/reducer/modalReducer";

const AddToCart = ({ product }) => {
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state?.authReducer?.login);

  const handleAddToCart = () => {
    if (currentUser) {
      dispatch(addProduct({ product, quantity: value }));
      notification.success({
        message: "Thêm vào giỏ hàng thành công",
        placement: "top",
        duration: 2,
      });
    } else {
      dispatch(setShowLoginModal(true));
    }
  };

  const [value, setValue] = useState(0);

  const handleIncrease = () => {
    setValue((prevValue) => {
      if (prevValue === product?.stock) {
        return product?.stock;
      } else {
        return prevValue + 1;
      }
    });
  };

  const handleDecrease = () => {
    setValue((prevValue) => {
      if (prevValue === 0) {
        return 0;
      } else {
        return prevValue - 1;
      }
    });
  };
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex items-center justify-between gap-5'>
        <Typography className='font-semibold text-lg text-gray-500'>Số lượng:</Typography>
        <div className='flex'>
          <Button onClick={handleDecrease} className='text-primary'>
            -
          </Button>
          <InputNumber
            value={value}
            onChange={setValue}
            className='w-14 border mx-1 cart-input'
            controls={false}
            defaultValue={1}
            min={0}
            max={product?.stock}
          />
          <Button onClick={handleIncrease} className='text-primary'>
            +
          </Button>
        </div>
      </div>
      <Typography className='font-semibold flex justify-between items-baseline'>
        <Typography className='text-lg text-gray-500'>Tổng cộng:</Typography>
        <NumericFormat
          className='font-bold text-lg text-orange-500 !text-center ml-2'
          value={value * product?.discountPrice}
          displayType='text'
          thousandSeparator='.'
          decimalSeparator=','
          suffix=' ₫'
        />
      </Typography>
      <Button type='primary' className='w-full h-[40px]' onClick={handleAddToCart}>
        Thêm vào giỏ
      </Button>
    </div>
  );
};

export default AddToCart;
