import { Card, Tooltip } from "antd";
import React from "react";

const Category = ({ title, image, description }) => {
  const { Meta } = Card;
  return (
    <Tooltip title={title}>
      <Card
        bodyStyle={{ padding: 0 }}
        hoverable
        cover={<img className='duration-300 max-w-[120px] max-h-[120px]' src={image} alt='' />}
        className='line-clamp-2 group flex flex-col w-full justify-between items-center text-center p-5'
      >
        <Meta title={title} description={description ? description : ""} className='pt-4 line-clamp-2' />
      </Card>
    </Tooltip>
  );
};

export default Category;
