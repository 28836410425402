import axios from "axios";
import instance from "./config";

const instanceNoAuth = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
instanceNoAuth.interceptors.response.use((response) => response?.data);

export const createSaleOrder = async (params) => instance.post("/sale-order/create-sale-order", params);

export const getSaleOrder = async (params) => instance.get("/sale-order/get-sale-order", params);

export const updateSaleOrder = async (params) => instance.put("/sale-order/update-sale-order", params);

export const deleteSaleOrder = async (params) => instance.delete("/sale-order/delete-sale-order", { params });
