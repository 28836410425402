import React from "react";

const TopBanner = ({ src, color }) => {
  return (
    <div className='h-[50px]' style={{ backgroundColor: color }}>
      <div
        className='wide h-[50px]'
        style={{
          backgroundImage: `url(${src})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundColor: color,
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
};

export default TopBanner;
