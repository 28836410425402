import React, { useEffect, useMemo, useState } from "react";
import { map } from "lodash";
import { Spin, Tooltip } from "antd";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { getAllCategory } from "../../../services/categoryServices";
import { setCategories } from "../../../redux/reducer/categoryReducer";
import Link from "antd/es/typography/Link";
import { getAllProduct } from "../../../services/productServices";

const MenuDropdown = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(null);

  const { data: categoryList, isFetching: fetchingCategory } = useQuery("getAllCategory", {
    queryFn: async () => {
      const data = await getAllCategory();
      dispatch(setCategories(data));
      return data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const {
    data: products,
    isFetching,
    refetch,
  } = useQuery("getProductListWithCategory", {
    queryFn: async () => {
      const response = await getAllProduct({ params: { category, page: 1, size: 10 } });
      return response?.data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const categories = useMemo(() => {
    if (categoryList && categoryList?.length >= 0) return categoryList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList, fetchingCategory]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div
      className='bg-white w-[896px] flex p-1 gap-3 rounded-lg'
      style={{
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
      }}
    >
      <div
        className='border border-gray-100 rounded-lg p-2'
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        }}
      >
        {map(categories, (i) => (
          <Link
            href={`/danh-muc/${i?.slug}`}
            className='cursor-pointer min-w-[250px] flex justify-start items-center flex-1 text-primary !transition-none hover:bg-primary hover:!text-white p-2 whitespace-nowrap rounded-lg'
            onMouseMove={() => setCategory(i?.slug)}
          >
            <img src={i?.image} width={28} height={28} alt='' />
            <p className='!m-0 !ml-3 font-medium'>{i?.name}</p>
          </Link>
        ))}
      </div>
      <div
        className='bg-white p-2 grid grid-cols-5 gap-x-3 gap-y-3 border border-gray-100 rounded-lg w-full'
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        }}
      >
        {map(products, (p) => (
          <Link href={`/${p?.category}/${p?.url}`} className='cursor-pointer'>
            <Tooltip title={p?.name} placement='bottom' color='rgba(0,0,0,0.7)'>
              <img src={p?.images?.[0]} alt='' className='aspect-square' />
              <p className='line-clamp-2 text-[12px] font-medium text-center mt-2'>{p?.name}</p>
            </Tooltip>
          </Link>
        ))}
        <Spin spinning={isFetching} size='small' tip='Đang tải'></Spin>
      </div>
    </div>
  );
};

export default MenuDropdown;
