import React from "react";
import { Layout } from "antd";
import SidebarDashboard from "./SidebarDashboard";
import FooterDashboard from "./FooterDashboard";

const Dashboard = ({ children }) => {
  return (
    <Layout className='min-h-screen'>
      <SidebarDashboard />
      <Layout>
        {/* <HeaderDashboard /> */}
        {children}
        <FooterDashboard />
      </Layout>
    </Layout>
  );
};
export default Dashboard;
