import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import persistor, { store } from "./redux/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

window.state = store;

const theme = {
  token: {
    colorPrimary: "#53a9a1",
    // colorPrimaryBg: "#53a9a1",
    colorLink: "#53a9a1",
    colorErrorText: "red",
    fontFamily: "Inter, sans-serif",
    colorPrimaryHover: "#53a9a1",
    colorPrimaryTextHover: "#53a9a1",
    lineWidth: 1,
    lineWidthBold: 1,
  },
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider theme={theme}>
            <App />
          </ConfigProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
