import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { findIndex, map } from "lodash";
import { useQuery } from "react-query";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { DeleteOutlined, EditOutlined, HomeOutlined, ScheduleOutlined } from "@ant-design/icons";

import { formatterNumber, getOrderStatus, parserNumber } from "../../utils";
import { deleteSaleOrder, getSaleOrder, updateSaleOrder } from "../../services/saleOrderService";
import moment from "moment";

const OrderManagerDashboard = () => {
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();

  const [record, setRecord] = useState({});

  useEffect(() => {
    document.title = "Quản lý đơn hàng";
  }, []);

  const calculatePaymentAmount = useCallback(() => {
    const paymentAmount =
      form.getFieldValue("totalAmount") -
      form.getFieldValue("productDiscountAmount") -
      form.getFieldValue("promotionDiscount") +
      form.getFieldValue("shippingFee");
    form.setFieldValue("paymentAmount", paymentAmount);
  }, [form]);

  const {
    data: saleOrderList,
    isFetching,
    refetch,
  } = useQuery({ queryKey: "getSaleOrder", queryFn: getSaleOrder, refetchOnWindowFocus: false, enabled: true });

  const dataSource = useMemo(() => {
    if (!isFetching && saleOrderList?.length > 0) {
      return saleOrderList;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleCloseModal = () => {
    setOpen(false);
    setRecord({});
    refetch();
  };

  const handleOnOpenModal = (record) => {
    form.setFieldsValue(record);
    setRecord(record);
    setOpen(true);
  };

  const handleOnSubmit = async (values) => {
    await updateSaleOrder(values)
      .then((res) => {
        handleCloseModal();
        notification.success({ description: "Cập nhật thành công!", placement: "top" });
      })
      .catch((err) => notification.error({ description: err?.message ? err.message : "Có lỗi xảy ra!", placement: "top" }));
  };

  const handleDeleteSaleOrder = async (id) =>
    deleteSaleOrder({ id })
      .then(() => notification.success({ description: "Xóa đơn hàng thành công !", placement: "top" }))
      .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top" }))
      .finally(() => refetch());

  const confirm = (id) => {
    Modal.confirm({
      title: "Cảnh báo",
      content: "Xác nhận xóa đơn hàng?",
      cancelText: "Hủy bỏ",
      okText: "Tiếp tục",
      onOk: () => handleDeleteSaleOrder(id),
      okType: "danger",
    });
  };

  const columns = useMemo(
    () => [
      {
        title: "STT",
        dataIndex: "_id",
        key: "_id",
        align: "center",
        render: (value) => <Typography className='text-sm'>{findIndex(saleOrderList ?? [], (item) => item?._id === value) + 1}</Typography>,
      },
      {
        title: "Ngày đặt",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        sorter: (a, b) => moment(a?.createdAt).valueOf() - moment(b?.createdAt).valueOf(),
        render: (value) => <Typography>{moment(value).format("HH:mm - DD/MM/YYYY")}</Typography>,
      },
      {
        title: "Khách hàng",
        dataIndex: "user",
        key: "user",
        align: "center",
        render: (value) => (
          <Link to={"/dashboard/users"}>
            <Typography className='text-primary font-medium'>{value?.username}</Typography>
          </Link>
        ),
        filters: map(saleOrderList, (item) => ({ value: item?.user?.username, text: item?.user?.username })),
        onFilter: (value, record) => record.user?.username.startsWith(value),
        filterSearch: true,
      },
      {
        title: "Mã đơn hàng",
        dataIndex: "documentNumber",
        align: "center",
        key: "documentNumber",
        render: (value, record) => (
          <Typography className='text-left text-primary cursor-pointer' onClick={() => handleOnOpenModal(record)}>
            {value}
          </Typography>
        ),
        filters: map(saleOrderList, (item) => ({ value: item?.documentNumber, text: item?.documentNumber })),
        onFilter: (value, record) => record.documentNumber.startsWith(value),
        filterSearch: true,
      },
      {
        title: "Ghi chú",
        dataIndex: "description",
        align: "center",
        key: "description",
        width: 150,
        filters: map(saleOrderList, (item) => ({ value: item?.description, text: item?.description })),
        onFilter: (value, record) => record.description.startsWith(value),
        filterSearch: true,
        render: (value) => (
          <Tooltip title={value}>
            <Typography className='text-left !line-clamp-1'>{value}</Typography>
          </Tooltip>
        ),
      },
      {
        title: "Tổng cộng",
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "right",
        sorter: (a, b) => a?.totalAmount - b?.totalAmount,
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Chiết khấu",
        dataIndex: "productDiscountAmount",
        key: "productDiscountAmount",
        align: "right",
        sorter: (a, b) => a?.productDiscountAmount - b?.productDiscountAmount,
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Mã giảm giá",
        dataIndex: "promotionDiscount",
        key: "promotionDiscount",
        align: "right",
        sorter: (a, b) => a?.promotionDiscount - b?.promotionDiscount,
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Phí ship",
        dataIndex: "shippingFee",
        key: "shippingFee",
        align: "right",
        sorter: (a, b) => a?.shippingFee - b?.shippingFee,
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Thanh toán",
        dataIndex: "paymentAmount",
        key: "paymentAmount",
        align: "right",
        sorter: (a, b) => a?.paymentAmount - b?.paymentAmount,
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        align: "center",
        render: (value) => (
          <Tag className='min-w-[120px] text-center' color={getOrderStatus(value)?.color}>
            {getOrderStatus(value)?.label}
          </Tag>
        ),
      },
      {
        title: "Tùy chọn",
        width: 100,
        align: "center",
        render: (record) => (
          <Space size={5}>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                onClick={() => {
                  form.setFieldsValue(record);
                  setRecord(record);
                  setOpen(true);
                }}
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button onClick={() => confirm(record?._id)} icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saleOrderList, form]
  );

  return (
    <Fragment>
      <Space direction='vertical' className='p-3'>
        <Breadcrumb
          items={[
            {
              href: "/dashboard",
              title: <HomeOutlined />,
            },
            {
              href: "/dashboard/order-manager",
              title: <span>Đơn hàng</span>,
            },
            {
              title: <span>Tất cả đơn hàng</span>,
            },
          ]}
        />

        <Space direction='vertical'>
          <Typography.Title level={4} className='my-4'>
            Đơn hàng
          </Typography.Title>
        </Space>
        <Table columns={columns} dataSource={dataSource} loading={isFetching} bordered size='small' pagination={{ size: "default", pageSize: 20 }} />
      </Space>
      <Modal
        open={open}
        title={
          <Space>
            <pan>Thông tin đơn hàng</pan>
            {
              <Tag className='min-w-[120px] text-center' color={getOrderStatus(Number(record?.status))?.color}>
                {getOrderStatus(Number(record?.status))?.label}
              </Tag>
            }
          </Space>
        }
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        keyboard
        centered
        maskClosable
        destroyOnClose={true}
        focusTriggerAfterClose={false}
        cancelText='Đóng'
        okText='Chỉnh sửa'
        width={900}
      >
        <Form
          form={form}
          id='saleOrderForm'
          // initialValues={initialValuesRef.current}
          onFinish={handleOnSubmit}
          spellCheck={false}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <div className='flex gap-5'>
            <div className='flex-[0.5] border p-3 rounded-lg border-gray-100'>
              <Col span={24}>
                <Form.Item name='documentNumber' label='Mã đơn hàng'>
                  <Input placeholder='Mã đơn hàng' disabled allowClear={true} prefix={<ScheduleOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='totalAmount' label='Tổng cộng'>
                  <InputNumber
                    className='w-full'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    placeholder='Chiết khấu'
                    controls={false}
                    allowClear={true}
                    min={0}
                    disabled
                    prefix={<EditOutlined className='mr-2 text-gray-500' />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='productDiscountAmount' label='Chiết khấu'>
                  <InputNumber
                    className='w-full'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    placeholder='Chiết khấu'
                    controls={false}
                    allowClear={true}
                    min={0}
                    onChange={calculatePaymentAmount}
                    prefix={<EditOutlined className='mr-2 text-gray-500' />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='promotionDiscount' label='Mã giảm giá'>
                  <InputNumber
                    className='w-full'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    placeholder='Mã giảm giá'
                    controls={false}
                    allowClear={true}
                    min={0}
                    onChange={calculatePaymentAmount}
                    prefix={<EditOutlined className='mr-2 text-gray-500' />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='shippingFee' label='Phí ship'>
                  <InputNumber
                    className='w-full'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    placeholder='Phí ship'
                    controls={false}
                    allowClear={true}
                    min={0}
                    onChange={calculatePaymentAmount}
                    prefix={<EditOutlined className='mr-2 text-gray-500' />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='paymentAmount' label='Thanh toán'>
                  <InputNumber
                    className='w-full'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    placeholder='Thanh toán'
                    controls={false}
                    allowClear={true}
                    min={0}
                    prefix={<EditOutlined className='mr-2 text-gray-500' />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='status' label='Trạng thái'>
                  <Select options={getOrderStatus()} placeholder='Trạng thái' allowClear={true} prefix={<ScheduleOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='description' label='Ghi chú'>
                  <Input.TextArea placeholder='Ghi chú' allowClear={true} prefix={<ScheduleOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={0} hidden={true}>
                <Form.Item name='_id' label='' hidden={true}>
                  <Input placeholder='' allowClear={true} />
                </Form.Item>
              </Col>
            </div>

            <Card title='Chi tiết sản phẩm' className='flex-[0.5]'>
              <List
                pagination={{
                  position: "bottom",
                  align: "end",
                }}
                dataSource={record?.details}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item?.images?.[0]} size={64} />}
                      title={
                        <Tooltip title={item?.name}>
                          <Link className='line-clamp-2' to={"/dashboard/products"}>
                            {item?.name}
                          </Link>
                        </Tooltip>
                      }
                      description={`${item?.quantity} x ${formatterNumber(item?.discountPrice)}đ = ${formatterNumber(item?.quantity * item?.discountPrice)}đ`}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default OrderManagerDashboard;
