import { map } from "lodash";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import routes from "./routes";
import Layout from "./components/Layout";
import Dashboard from "./components/Dashboard";

import "./App.css";
import { useSelector } from "react-redux";
import { Button, Result } from "antd";

function App() {
  const { currentUser } = useSelector((state) => state?.authReducer?.login);
  return (
    <Router>
      <Routes>
        {map(routes, (route) => {
          const Element = route.element;
          return (
            <Route
              path={route.path}
              key={route.path}
              element={
                route?.isDashboard ? (
                  currentUser?.role === "admin" || currentUser?.role === "employee" ? (
                    <Dashboard>
                      <Element />
                    </Dashboard>
                  ) : (
                    <Layout>
                      <Result
                        status='403'
                        title='403'
                        subTitle='Xin lỗi bạn không có quyền truy cập trang này!'
                        extra={
                          <Button type='primary'>
                            <Link to={"/"}>Về trang chủ</Link>
                          </Button>
                        }
                      />
                    </Layout>
                  )
                ) : (
                  <Layout>
                    <Element />
                  </Layout>
                )
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
}

export default App;
