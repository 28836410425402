import { ShopOutlined, DashboardOutlined, UserOutlined, RightOutlined, LeftOutlined, PercentageOutlined, ReadOutlined } from "@ant-design/icons";
import { Image, Menu, notification } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../services/userServices";
import { useDispatch } from "react-redux";

const SidebarDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleOnLogout = async () =>
    await logoutUser(null, dispatch)
      .then((res) => notification.success({ description: res?.response?.data?.message || "Đăng xuất thành công!", placement: "top" }))
      .finally(() => navigate("/"));

  const items = [
    {
      label: <Link to={"/dashboard/"}>Tổng quan</Link>,
      key: "overview",
      icon: <DashboardOutlined />,
    },
    {
      label: <Link to={"/dashboard/order-manager"}>Đơn hàng</Link>,
      key: "orders",
      icon: <i className='bi bi-receipt'></i>,
    },
    {
      label: <Link to={"/dashboard/users"}>Người dùng</Link>,
      key: "users",
      icon: <UserOutlined />,
    },
    {
      label: "Sản phẩm",
      icon: <ShopOutlined />,
      children: [
        {
          label: <Link to={"/dashboard/products"}>Tất cả sản phẩm</Link>,
          key: "products",
        },
        {
          label: <Link to={"/dashboard/categories"}>Danh mục</Link>,
          key: "categories",
        },
        {
          label: <Link to={"/dashboard/brands"}>Thương hiệu</Link>,
          key: "brands",
        },
      ],
    },
    {
      label: "Bài viết",
      icon: <ReadOutlined />,
      children: [
        {
          label: <Link to={"/dashboard/news"}>Tất cả bài viết</Link>,
          key: "news",
        },
        {
          label: <Link to={"/dashboard/subjects"}>Chuyên mục</Link>,
          key: "subjects",
        },
      ],
    },
    {
      label: "Quản lý file",
      icon: <i className='bi bi-folder'></i>,
      children: [
        {
          label: <Link to={"/dashboard/files"}>Hình ảnh</Link>,
          key: "files",
        },
      ],
    },

    {
      label: "Khuyến mãi",
      icon: <PercentageOutlined />,
      children: [
        {
          label: <Link to='/dashboard/flash-sale'>Ưu đãi chớp nhoáng</Link>,
          key: "flash-sale",
        },
        {
          label: <Link to={"/dashboard/discount"}>Chiết khấu sản phẩm</Link>,
          key: "discount",
        },
        {
          label: <Link to={"/dashboard/coupon"}>Mã giảm giá</Link>,
          key: "coupon",
        },
      ],
    },

    {
      label: <Link onClick={handleOnLogout}>Đăng xuất</Link>,
      key: "logout",
      icon: <i className='bi bi-box-arrow-right'></i>,
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      trigger={<p className='bg-white text-black border-t'>{collapsed ? <RightOutlined /> : <LeftOutlined />}</p>}
      width={215}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        backgroundColor: "white",
        left: 0,
        top: 0,
        zIndex: 1,
      }}
    >
      <Link to={"/"} className='demo-logo-vertical flex items-center justify-center px-6 py-5 mb-4 border-b'>
        <Image className='bg-primary p-2 rounded-3xl' src='/logo.png' preview={false} />
      </Link>
      <Menu theme='light' defaultSelectedKeys={["overview"]} mode='inline' items={items} />
    </Sider>
  );
};

export default SidebarDashboard;
