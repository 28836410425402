import { DeleteOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import LinkAntd from "antd/es/typography/Link";
import { map, reduce } from "lodash";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeProduct } from "../../../redux/reducer/cartReducer";

const Cart = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cartReducer);

  return (
    <div className='bg-white w-[350px] shadow-2xl rounded-lg p-3 border'>
      <Typography className='mb-5'>Giỏ hàng</Typography>
      {map(cart, (element, index) => (
        <div className='rounded-lg mb-3'>
          <div className='flex items-center'>
            <LinkAntd onClick={() => (window.location.pathname = `/${element?.product?.category}/${element?.product?.url}`)} className='flex'>
              <img className='border p-1 rounded-md mr-3 w-[64px]' src={element?.product?.images?.[0]} alt='' />
              <div className='self-start w-[204px]'>
                <p className='font-medium line-clamp-2 !mb-0'>{element?.product?.name}</p>
                <div className='flex justify-between'>
                  <NumericFormat
                    className='text-orange-500 font-medium'
                    value={element?.product?.discountPrice}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    suffix=' ₫'
                  />
                  <span className='text-gray-500 font-light'>x{element?.quantity}</span>
                </div>
              </div>
            </LinkAntd>
            <div className='ml-auto w-[32px] text-right cursor-pointer text-gray-500' onClick={() => dispatch(removeProduct(element?.product?.url))}>
              <DeleteOutlined />
            </div>
          </div>
          <Divider type='horizontal' className='p-0 mb-2 mt-2'></Divider>
        </div>
      ))}
      <div className='flex items-center justify-between'>
        <NumericFormat
          className='text-gray-400 font-normal'
          value={reduce(
            cart,
            (acc, cur) => {
              return (acc += cur?.product?.discountPrice * cur?.quantity);
            },
            0
          )}
          displayType='text'
          thousandSeparator='.'
          decimalSeparator=','
          suffix=' ₫'
          prefix='Tổng cộng: '
        />
        <Button type='primary'>
          <Link to={"/gio-hang"}>Xem giỏ hàng</Link>
        </Button>
      </div>
    </div>
  );
};

export default Cart;
