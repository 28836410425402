import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Breadcrumb, Button, Col, Form, Image, Input, Modal, Row, Select, Space, Table, Tag, Tooltip, Typography, notification, Upload } from "antd";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  MoreOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { filter, findIndex, isArray, isString, map, toUpper } from "lodash";
import moment from "moment";
import { useQuery } from "react-query";
import { deleteUser, getAllUser, updateUser } from "../../services/userServices";
import { getDistrict, getProvince, getWard } from "../../services/provinceServices";
import { getRole, roles } from "../../utils";
import * as constant from "../../data/constant";
import Link from "antd/es/typography/Link";
import { uploadMedia } from "../../services/uploadServices";

const UsersDashboard = () => {
  const { Dragger } = Upload;

  const [openModal, setOpenModal] = useState(false);

  const [updating, setUpdating] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const initialValuesRef = useRef({});

  const { data: users, isFetching, refetch } = useQuery({ queryFn: getAllUser, enabled: true, refetchOnWindowFocus: true });

  const { data: province } = useQuery({ queryKey: "getProvince", queryFn: getProvince, refetchOnWindowFocus: false });

  const { data: district } = useQuery({ queryKey: "getDistrict", queryFn: getDistrict, refetchOnWindowFocus: false });

  const { data: ward, isSuccess } = useQuery({ queryKey: "getWard", queryFn: getWard, refetchOnWindowFocus: false });

  const [address, setAddress] = useState({ province: [], district: [], ward: [] });

  useEffect(() => {
    document.title = "Quản lý người dùng";
  }, []);

  useEffect(() => {
    setAddress({ province, district, ward });
    setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, isSuccess]);

  const handleDeleteUser = (id) =>
    deleteUser({ id })
      .then(() => notification.success({ description: "Xóa người dùng thành công !", placement: "top", style: { width: "300px" } }))
      .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }))
      .finally(() => refetch());

  const handleOnSubmit = async (values) => {
    setUpdating(true);
    if (!isString(values?.avatar)) {
      const formData = new FormData();
      formData.append("file", values?.avatar?.file);
      await uploadMedia(formData)
        .then((res) => (values.avatar = res?.url))
        .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }));
    }
    await updateUser(values)
      .then(() => {
        notification.success({ description: "Cập nhật thành công !", placement: "top", style: { width: "300px" } });
        setOpenModal(false);
      })
      .catch((err) => {
        notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
      })
      .finally(() => {
        setUpdating(false);
        refetch();
      });
  };

  const draggerProps = {
    accept: ["image/jpg", "image/jpeg", "image/png"],
    multiple: false,
    showUploadList: true,
    beforeUpload: () => false,
  };

  const confirm = (id) => {
    Modal.confirm({
      title: "Cảnh báo",
      content: "Xác nhận xóa người dùng?",
      cancelText: "Hủy bỏ",
      okText: "Tiếp tục",
      onOk: () => handleDeleteUser(id),
      okType: "danger",
    });
  };

  const columns = useMemo(() => {
    return [
      {
        title: "STT",
        dataIndex: "_id",
        key: "_id",
        align: "center",
        render: (value) => <Typography className='text-sm text-primary'>{findIndex(users ?? [], (item) => item?._id === value) + 1}</Typography>,
      },
      {
        title: "Tài khoản",
        width: 300,
        dataIndex: "username",
        key: "username",
        render: (value, record) => (
          <Space
            className='cursor-pointer'
            align='center'
            size={0}
            split={<MoreOutlined className='opacity-30' />}
            onClick={() => {
              setOpenModal(true);
              initialValuesRef.current = record;
            }}
          >
            <Avatar src={record?.avatar} draggable icon={<UserOutlined />} />
            <Link className='text-primary font-medium'>{value}</Link>
          </Space>
        ),
      },
      {
        title: "Phân quyền",
        width: 300,
        key: "role",
        dataIndex: "role",
        align: "left",
        render: (record) => {
          let color = "green";
          if (record === "employee") {
            color = "blue";
          }
          if (record === "admin") {
            color = "orange";
          }
          return (
            <Tag color={color} key={record} className='text-[10px]'>
              {toUpper(getRole(record)?.label)}
            </Tag>
          );
        },
      },
      {
        title: "Họ và tên",
        width: 300,
        dataIndex: "fullname",
        key: "fullname",
      },
      {
        title: "Email",
        width: 300,
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Số điện thoại",
        dataIndex: "phone",
        width: 300,
        key: "phone",
      },
      {
        title: "Địa chỉ",
        width: 500,
        dataIndex: "road",
        key: "road",
      },
      {
        title: "Ngày tạo",
        dataIndex: "createDate",
        align: "center",
        width: 150,
        key: "createDate",
        render: (record) => moment(record).format("DD/MM/YYYY"),
      },
      {
        title: "Tùy chọn",
        width: 150,
        align: "center",
        render: (record) => (
          <Space size={5}>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  initialValuesRef.current = record;
                }}
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button onClick={() => confirm(record?._id)} icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const dataSourceFn = useCallback(
    (data) => {
      if (isArray(data)) {
        return map(data, (item, index) => ({
          key: index,
          _id: item?._id,
          username: item?.username,
          fullname: item?.fullname,
          email: item?.email,
          phone: item?.phone,
          road: item?.road,
          ward: item?.ward,
          district: item?.district,
          province: item?.province,
          role: item?.role,
          avatar: item?.avatar,
          createDate: item?.createdAt,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching]
  );

  return (
    <Space>
      <Space direction='vertical' className='p-3'>
        <Breadcrumb
          items={[
            {
              href: "/dashboard",
              title: <HomeOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <UserOutlined />
                  <span>{constant.USER.TITLE}</span>
                </>
              ),
            },
            {
              title: (
                <>
                  <TeamOutlined />
                  <span>Tất cả người dùng</span>
                </>
              ),
            },
          ]}
        />

        <Space direction='vertical'>
          <Typography.Title level={4} className='my-4'>
            {constant.USER.TITLE}
          </Typography.Title>
        </Space>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={dataSourceFn(users) ?? []}
          bordered
          size='small'
          pagination={{ size: "default", pageSize: 15 }}
        />
      </Space>
      <Modal
        open={openModal}
        keyboard
        centered
        maskClosable
        title='Chỉnh sửa người dùng'
        width={1000}
        okText='Cập nhật'
        cancelText='Huỷ bỏ'
        onCancel={() => setOpenModal(false)}
        destroyOnClose={true}
        confirmLoading={updating}
        focusTriggerAfterClose={false}
        okButtonProps={{
          htmlType: "submit",
          form: "editUserForm",
        }}
      >
        <Form id='editUserForm' initialValues={initialValuesRef.current} onFinish={handleOnSubmit} spellCheck={false} labelCol={{ span: 24 }} wrapperCol={24}>
          <Row gutter={50}>
            <Col span={16}>
              <Row justify={"center"} gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name='username'
                    label='Tài khoản'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên đăng nhập!",
                      },
                    ]}
                  >
                    <Input placeholder='Tên đăng nhập' allowClear={true} prefix={<UserOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Phân quyền' name='role'>
                    <Select placeholder='Phân quyền' options={roles} prefix={<LockOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='email'
                    label='Email'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập email!",
                      },
                    ]}
                  >
                    <Input type='email' placeholder='Email' allowClear={true} prefix={<MailOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='phone'
                    label='Số điện thoại'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số điện thoại!",
                      },
                    ]}
                  >
                    <Input type='number' placeholder='Số điện thoại' allowClear={true} prefix={<MobileOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Họ và tên' name='fullname'>
                    <Input placeholder='Họ và tên' allowClear={true} prefix={<EditOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Địa chỉ' name='road'>
                    <Input placeholder='Nhập số nhà tên đường' allowClear={true} prefix={<HomeOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Tỉnh/Thành phố' name='province'>
                    <Select
                      placeholder='Chọn tỉnh thành phố'
                      allowClear={true}
                      options={map(address.province, (item) => ({ label: item?.name, value: item?.idProvince }))}
                      showSearch={true}
                      optionFilterProp='label'
                      virtual={false}
                      onSelect={(val) => {
                        const filteredDistrict = filter(address.district, (item) => item?.idProvince === val);
                        setAddress((prev) => ({ ...prev, district: filteredDistrict }));
                        setDisabled(false);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Quận/Huyện' name='district'>
                    <Select
                      placeholder='Chọn quận huyện'
                      allowClear={true}
                      options={map(address?.district, (item) => ({ label: item?.name, value: item?.idDistrict }))}
                      showSearch={true}
                      optionFilterProp='label'
                      virtual={false}
                      onSelect={(val) => {
                        const filteredWard = filter(address.ward, (item) => item?.idDistrict === val);
                        setAddress((prev) => ({ ...prev, ward: filteredWard }));
                      }}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name='ward' label='Phường/Xã'>
                    <Select
                      placeholder='Chọn phường xã'
                      allowClear={true}
                      options={map(address?.ward, (item) => ({ label: item?.name, value: item?.idWard }))}
                      showSearch={true}
                      optionFilterProp='label'
                      virtual={false}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={0} hidden>
                  <Form.Item name='_id'>
                    <Input disabled={disabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} className='flex items-center justify-center'>
              <Row>
                <Col align='center' span={24}>
                  <Space direction='vertical' className='mb-5'>
                    <Image src={initialValuesRef.current?.avatar} width={150} height={150} className='rounded-full border' />
                    <Button type='primary' className='font-Kanit font-normal cursor-pointer text-white'>
                      {toUpper(initialValuesRef.current?.fullname || initialValuesRef.current?.username)}
                    </Button>
                  </Space>
                </Col>
                <Col align='center' span={24}>
                  <Form.Item name='avatar'>
                    <Dragger {...draggerProps} name='avatar' listType='text'>
                      <p className='ant-upload-drag-icon'>
                        <CloudUploadOutlined />
                      </p>
                      <p className='ant-upload-text'>Kéo thả hình ảnh vào đây</p>
                      <p className='ant-upload-hint'>Để thay đổi ảnh đại diện</p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Space>
  );
};

export default UsersDashboard;
