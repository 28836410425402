import axios from "axios";
import instance from "./config";

const instanceNoAuth = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
instanceNoAuth.interceptors.response.use((response) => response?.data);

export const createBanner = async (params) => instance.post("/banner/create", params);

export const getBanner = async (params) => instanceNoAuth.get("/banner/get-banner", params);

export const updateBanner = async (params) => instanceNoAuth.put("/banner/update-banner", params);

export const createSlider = async (params) => instance.post("/slider/create", params);

export const getSlider = async (params) => instanceNoAuth.get("/slider/get-slider", params);

export const updateSlider = async (params) => instanceNoAuth.get("/slider/update-slider", params);
