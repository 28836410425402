import { createSlice } from "@reduxjs/toolkit";
import { find } from "lodash";

export const recentProductSlice = createSlice({
  name: "recentProduct",
  initialState: {
    recentProduct: [],
  },
  reducers: {
    setRecentProduct: (state, action) => {
      if (state.recentProduct.length >= 8) {
        state.recentProduct.pop();
        state.recentProduct.unshift(action.payload);
      } else {
        state.recentProduct.unshift(action.payload);
      }
      state.recentProduct = Array.from(new Set(state.recentProduct.map((product) => product?._id))).map((_id) => {
        return find(state.recentProduct, (product) => product?._id === _id);
      });
    },
  },
});

export const { setRecentProduct } = recentProductSlice.actions;

export default recentProductSlice.reducer;
