import axios from "axios";
import instance from "./config";

const instanceNoAuth = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
instanceNoAuth.interceptors.response.use((response) => response?.data);

export const createNews = async (params) => instance.post("/news/create-news", params);

export const getAllNews = async (params) => instanceNoAuth.get("/news/get-all-news", params);

export const updateNews = async (params) => instance.put("/news/update-news", params);

export const deleteNews = async (params) => instance.delete("/news/delete-news", { params });
