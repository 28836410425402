import { Image, Typography } from "antd";
import React from "react";

const Promotion = (props) => {
  const { brand } = props;
  return (
    <div className='flex flex-col justify-between items-center text-center bg-white p-4 rounded-lg '>
      <Image preview={false} className='mb-2 rounded-lg !h-20 select-none' src={brand?.image} alt={brand?.description} />
      <Typography className='font-medium select-none'>{brand?.name}</Typography>
    </div>
  );
};

export default Promotion;
