import { createSlice } from "@reduxjs/toolkit";

export const recentKeywordSlice = createSlice({
  name: "recentKeyword",
  initialState: {
    recentKeywords: [],
  },
  reducers: {
    setRecentKeyword: (state, action) => {
      if (action.payload) {
        if (state.recentKeywords.length >= 5) {
          state.recentKeywords.pop();
          state.recentKeywords.unshift(action.payload);
        } else {
          state.recentKeywords.unshift(action.payload);
        }
        state.recentKeywords = Array.from(new Set(state.recentKeywords));
      }
    },
    removeAllRecentKeyword: (state, action) => {
      state.recentKeywords = action.payload;
    },
  },
});

export const { setRecentKeyword, removeAllRecentKeyword } = recentKeywordSlice.actions;

export default recentKeywordSlice.reducer;
