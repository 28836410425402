import React, { useEffect } from "react";
import { Button, Image } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { useQuery } from "react-query";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getBanner, getSlider } from "../../services/flieServices";
import { find, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setBanners, setSliders } from "../../redux/reducer/imageReducer";

const Banner = () => {
  const { banners, sliders } = useSelector((state) => state?.imageReducer);

  const dispatch = useDispatch();

  const { data: bannerList, isSuccess } = useQuery({ queryKey: "getBanners", queryFn: getBanner, refetchOnWindowFocus: false });

  const { data: sliderList, isSuccess: getSliderSuccess } = useQuery({ queryKey: "getSliders", queryFn: getSlider, refetchOnWindowFocus: false });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setBanners(bannerList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (getSliderSuccess) {
      dispatch(setSliders(sliderList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSliderSuccess]);

  return (
    <div className='lg:relative lg:h-[300px] lg:overflow-hidden lg:mx-auto lg:gap-[15px] lg:flex wide px-3 lg:px-0 md:px-3 group'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={{
          nextEl: ".button-next-elm",
          prevEl: ".button-prev-elm",
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{ pauseOnMouseEnter: true, delay: 3000 }}
        keyboard={{
          enabled: true,
        }}
        style={{ borderRadius: "15px" }}
        modules={[Navigation, Autoplay, Pagination]}
        className='mySwiper flex-1'
      >
        {map(sliders, (item) => (
          <SwiperSlide>
            <Image src={item?.url || "https://pic.pnnet.dev/1590x560"} alt='' />
          </SwiperSlide>
        ))}
        <Button className='absolute right-5 top-[50%] translate-y-[-50%] button-next-elm z-10 group-hover:opacity-100 scale-125 opacity-0 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
          <RightOutlined className='text-primary' />
        </Button>
        <Button className='absolute left-5 top-[50%] translate-y-[-50%] button-prev-elm z-10 group-hover:opacity-100 scale-125 opacity-0 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
          <LeftOutlined className='text-primary' />
        </Button>
      </Swiper>
      <div className='lg:flex lg:flex-col lg:justify-between lg:w-[372px] lg:gap-[15px] hidden md:hidden'>
        <div
          className='rounded-xl'
          style={{
            backgroundImage: `url(${find(banners, (item) => item?.name === "banner1")?.url || "https://pic.pnnet.dev/1490x570"})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
          }}
        >
          <Image
            src={find(banners, (item) => item?.name === "banner1")?.url || "https://pic.pnnet.dev/1490x570"}
            alt=''
            className='rounded-xl'
            loading='lazy'
          />
        </div>
        <div
          className='rounded-xl'
          style={{
            backgroundImage: `url(${find(banners, (item) => item?.name === "banner2")?.url || "https://pic.pnnet.dev/1490x570"})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
          }}
        >
          <Image
            src={find(banners, (item) => item?.name === "banner2")?.url || "https://pic.pnnet.dev/1490x570"}
            alt=''
            className='rounded-xl'
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
