import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Link } from "react-router-dom";
import Brand from "./Brand";
import { useQuery } from "react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { getAllBrand } from "../../services/brandServices";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Button, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const Brands = (props) => {
  const [brands, setBrands] = useState(null);
  const { data, isFetching } = useQuery({ queryFn: getAllBrand, enabled: true, refetchOnWindowFocus: false });

  useEffect(() => {
    if (!isFetching) setBrands(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <div className='wide mx-auto my-8 relative group'>
      <Swiper
        slidesPerView={5}
        loop={true}
        spaceBetween={12}
        autoplay={{ pauseOnMouseEnter: true, delay: 3000 }}
        navigation={{
          nextEl: ".button-next-brands",
          prevEl: ".button-prev-brands",
        }}
        className='mySwiper px-3 lg:px-0'
        modules={[Navigation, Autoplay]}
        breakpoints={
          // when window width is >= 320px
          {
            320: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
              spaceBetween: 12,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 5,
              spaceBetween: 12,
            },
          }
        }
      >
        {map(brands, (brand) => (
          <SwiperSlide className='rounded-lg'>
            <Tooltip title={brand?.name} placement='top'>
              <Link to={`/thuong-hieu/${brand?.name}`}>
                <Brand brand={brand} />
              </Link>
            </Tooltip>
          </SwiperSlide>
        ))}
      </Swiper>
      <Button className='absolute right-4 top-[50%] translate-y-[-50%] button-next-brands z-10 group-hover:scale-125 group-hover:opacity-100 opacity-0 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
        <RightOutlined className='text-primary' />
      </Button>
      <Button className='absolute left-4 top-[50%] translate-y-[-50%] button-prev-brands z-10 group-hover:scale-125 group-hover:opacity-100 opacity-0 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
        <LeftOutlined className='text-primary' />
      </Button>
    </div>
  );
};

export default Brands;
