/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined, EditOutlined, FormOutlined, HomeOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Form, Image, Input, Modal, Row, Space, Table, Tooltip, Typography, Upload, notification } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createCategory, deleteCategory, getAllCategory, updateCategory } from "../../services/categoryServices";
import TextArea from "antd/es/input/TextArea";
import { setEditor } from "../../redux/reducer/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, isArray, isString, map } from "lodash";
import { uploadMedia } from "../../services/uploadServices";
import { useQuery } from "react-query";

const CategoryDashboard = () => {
  const dispatch = useDispatch();

  const [updating, setUpdating] = useState(false);

  const { isEditor } = useSelector((state) => state?.modalReducer);

  const [open, setOpenModal] = useState(false);

  const initialValuesRef = useRef({});

  useEffect(() => {
    document.title = "Quản lý danh mục";
  }, []);

  const { data: categories, isFetching, refetch } = useQuery({ queryFn: getAllCategory, enabled: true, refetchOnWindowFocus: false });

  const handleCreateCategory = () => {
    dispatch(setEditor(false));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    initialValuesRef.current = {};
  };

  const handleOnSubmit = async (values) => {
    setUpdating(true);
    if (!isString(values?.image)) {
      const formData = new FormData();
      formData.append("file", values?.image?.file);
      await uploadMedia(formData)
        .then((res) => (values.image = res?.url))
        .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }));
    }
    if (isEditor) {
      await updateCategory(values)
        .then(() => {
          notification.success({ description: "Cập nhật thành công !", placement: "top", style: { width: "300px" } });
          initialValuesRef.current = {};
          setOpenModal(false);
        })
        .catch((err) => {
          notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
        })
        .finally(() => {
          initialValuesRef.current = {};
          setUpdating(false);
          refetch();
        });
    } else {
      await createCategory(values)
        .then(() => {
          notification.success({ description: "Tạo mới thành công !", placement: "top", style: { width: "300px" } });
          initialValuesRef.current = {};
          setOpenModal(false);
        })
        .catch((err) => {
          notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
        })
        .finally(() => {
          setUpdating(false);
          refetch();
        });
    }
  };

  const handleDeleteCategory = (id) =>
    deleteCategory({ id })
      .then(() => notification.success({ description: "Xóa danh mục thành công !", placement: "top", style: { width: "300px" } }))
      .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }))
      .finally(() => refetch());

  const uploadProps = {
    accept: ["image/jpg", "image/jpeg", "image/png"],
    listType: "picture-card",
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    defaultFileList: [{ url: initialValuesRef.current?.image }],
    beforeUpload: () => false,
  };

  const confirm = (id) => {
    Modal.confirm({
      title: "Cảnh báo",
      content: "Xác nhận xóa danh mục?",
      cancelText: "Hủy bỏ",
      okText: "Tiếp tục",
      onOk: () => handleDeleteCategory(id),
      okType: "danger",
    });
  };

  const columns = useMemo(
    () => [
      {
        title: "STT",
        dataIndex: "_id",
        key: "_id",
        align: "center",
        width: 50,
        render: (value) => <Typography className='text-sm'>{findIndex(categories ?? [], (item) => item?._id === value) + 1}</Typography>,
      },
      {
        title: "Hình ảnh",
        width: 200,
        align: "center",
        dataIndex: "image",
        key: "image",
        render: (value, record) => <Image width={60} src={value} />,
      },
      {
        title: "Tên danh mục",
        width: 300,
        dataIndex: "name",
        key: "name",
        align: "center",
        render: (value, record) => <Typography className='text-sm font-semibold'>{value}</Typography>,
      },
      {
        title: "Đường dẫn",
        width: 300,
        dataIndex: "slug",
        key: "slug",
        align: "center",
        render: (value, record) => <Typography className='text-sm'>{value}</Typography>,
      },
      {
        title: "Thông tin",
        dataIndex: "description",
        key: "description",
        align: "center",
        render: (value, record) => <Typography className='text-sm text-left'>{value}</Typography>,
      },
      {
        title: "Tùy chọn",
        width: 150,
        align: "center",
        render: (record) => (
          <Space size={5}>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                onClick={() => {
                  dispatch(setEditor(true));
                  setOpenModal(true);
                  initialValuesRef.current = record;
                }}
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button onClick={() => confirm(record?._id)} icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ),
      },
    ],
    [categories]
  );

  const dataSourceFn = useCallback(
    (data) => {
      if (isArray(data)) {
        return map(data, (item, index) => ({
          key: index,
          _id: item?._id,
          name: item?.name,
          createDate: item?.createdAt,
          ...item,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching]
  );

  return (
    <>
      <Space direction='vertical' className='p-3'>
        <Breadcrumb
          items={[
            {
              href: "/dashboard",
              title: <HomeOutlined />,
            },
            {
              href: "",
              title: "Danh mục",
            },
            {
              title: "Tất cả danh mục",
            },
          ]}
        />
        <Space direction='vertical'>
          <Typography.Title level={4} className='my-4'>
            Danh mục
          </Typography.Title>
        </Space>
        <Button type='primary' className='mb-3' onClick={handleCreateCategory}>
          Tạo mới
        </Button>
        <Table loading={isFetching} columns={columns} dataSource={dataSourceFn(categories) ?? []} bordered size='small' />
      </Space>
      <Modal
        open={open}
        width={500}
        centered
        closable
        onCancel={handleCloseModal}
        keyboard
        maskClosable
        confirmLoading={updating}
        title='Thêm mới danh mục'
        okText={isEditor ? "Chỉnh sửa" : "Tạo mới"}
        cancelText='Huỷ bỏ'
        destroyOnClose={true}
        focusTriggerAfterClose={false}
        okButtonProps={{
          htmlType: "submit",
          form: "categoryForm",
        }}
      >
        <Form id='categoryForm' spellCheck={false} labelCol={{ span: 24 }} wrapperCol={24} onFinish={handleOnSubmit} initialValues={initialValuesRef.current}>
          <Col span={24}>
            <Row justify={"center"} gutter={12}>
              <Col span={24}>
                <Form.Item
                  name='name'
                  label='Tên danh mục'
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên danh mục!",
                    },
                  ]}
                >
                  <Input placeholder='Tên danh mục' allowClear={true} prefix={<FormOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
                <Form.Item
                  name='description'
                  label='Mô tả'
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên chuyên mục!",
                    },
                  ]}
                >
                  <TextArea placeholder='Nhập mô tả' allowClear={true} autoSize prefix={<InfoCircleOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
                <Form.Item
                  name='slug'
                  label='Đường dẫn'
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên chuyên mục!",
                    },
                  ]}
                >
                  <TextArea placeholder='Nhập đường dẫn' allowClear={true} autoSize prefix={<InfoCircleOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
                <Form.Item name='image' label='Ảnh đại diện'>
                  <Upload {...uploadProps}>
                    <div>
                      <PlusOutlined />
                      <p className='pt-2 font-Poppins'>Tải ảnh lên</p>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={0} hidden>
                <Form.Item name='_id'>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default CategoryDashboard;
