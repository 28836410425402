import { Card, Col, Divider, Row, Tag, Typography } from "antd";
import { isEmpty, map } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

const Post = ({ position, post = {}, posts = [], subject = {}, isSubjectPage = false }) => {
  return (
    <>
      {position === "top" ? (
        <Link to={`/bai-viet/${post?.url}`}>
          <Card
            bordered={false}
            cover={
              <div
                className='!h-[350px]'
                style={{
                  marginTop: 8,
                  borderRadius: 5,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${post?.images?.[0]})`,
                }}
              />
            }
            bodyStyle={{
              padding: "10px 0",
            }}
            className='rounded-md !shadow-none'
          >
            <Tag color='#edf0f3' className='font-medium px-[3px] mb-1 rounded-sm !text-[#727886]'>
              {post?.subject || ""}
            </Tag>
            <Typography.Title level={3} className='!mb-1 line-clamp-2'>
              {post?.title || ""}
            </Typography.Title>
            <p className='m-0 line-clamp-1'>{post?.description || ""}</p>
          </Card>
        </Link>
      ) : position === "side" ? (
        <Link to={`/bai-viet/${post?.url}`}>
          <Card
            bordered={false}
            bodyStyle={{
              padding: "8px 0",
            }}
            className='rounded-md !shadow-none'
          >
            <Row gutter={20}>
              <Col
                span={8}
                style={{
                  borderRadius: 5,
                  backgroundSize: "cover",
                  height: 100,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${post?.images?.[0]})`,
                }}
              />
              <Col span={16}>
                <Tag color='#edf0f3' className='font-medium px-[3px] mb-1 rounded-sm !text-[#727886]'>
                  {post?.subject || ""}
                </Tag>
                <Typography.Title level={5} className='!mb-1 line-clamp-2'>
                  {post?.title || ""}
                </Typography.Title>
                <Typography className='line-clamp-1'>{post?.description || ""}</Typography>
              </Col>
            </Row>
          </Card>
        </Link>
      ) : !isEmpty(posts) ? (
        <Card
          bordered={false}
          bodyStyle={{
            padding: "5px 20px",
          }}
          className='shadow-sm rounded-md'
        >
          <Link to={`/chuyen-muc/${subject?.slug}`}>
            <Typography.Title level={4} className='!mb-6 mt-3'>
              {isSubjectPage ? "" : subject?.name}
            </Typography.Title>
          </Link>
          <div className='flex gap-2'>
            <Col
              span={4}
              style={{
                borderRadius: 4,
                height: isSubjectPage ? 120 : 100,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${posts?.[0]?.images?.[0]})`,
              }}
            />
            <Col span={20}>
              <Link to={`/bai-viet/${posts?.[0]?.url}`}>
                {isSubjectPage ? (
                  <Tag color='#edf0f3' className='font-medium px-[3px] mb-1 rounded-sm !text-[#727886]'>
                    {posts?.[0]?.subject}
                  </Tag>
                ) : (
                  <></>
                )}
                <Typography.Title level={5} className='!mb-2 line-clamp-2'>
                  {posts?.[0]?.title || ""}
                </Typography.Title>
                <Typography className='m-0 !line-clamp-3 text-md font-normal'>{posts?.[0]?.description || ""}</Typography>
              </Link>
            </Col>
          </div>
          <Divider type='horizontal' />
          {!isSubjectPage && (
            <>
              <ul className='flex gap-8 h-11'>
                {map(posts?.slice(1, 4), (post, index) => (
                  <Link to={`/bai-viet/${post?.url}`} className='line-clamp-2' key={index}>
                    <i className='bi bi-newspaper' />
                    <strong className='ml-2 text-gray-600'> {post?.title || ""}</strong>
                  </Link>
                ))}
              </ul>
            </>
          )}
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default Post;
