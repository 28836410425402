import React from "react";
import { formatNumberToString } from "../../utils";

const SoldDetail = ({ product }) => {
  return (
    <div class='flex content-center justify-between'>
      <div class='flex items-center justify-start space-x-1 '>
        <div class='flex items-center justify-start'>
          <div class='grid h-6 w-6 '>
            <span class='inline-flex align-[-0.125em] justify-center max-h-full max-w-full w-4 h-4 text-neutral-700'>
              <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M17.222 2.00002C16.3254 1.99786 15.4391 2.19001 14.6239 2.56323C13.8088 2.93646 13.0842 3.48191 12.5 4.16202C11.9158 3.48191 11.1912 2.93646 10.3761 2.56323C9.5609 2.19001 8.67453 1.99786 7.77799 2.00002C6.55113 1.99725 5.35047 2.35483 4.32502 3.02836C3.29957 3.7019 2.49448 4.66174 2.00968 5.78875C1.52488 6.91576 1.38171 8.16034 1.59793 9.36799C1.81416 10.5757 2.38027 11.6932 3.22599 12.582L11.779 21.471C11.8723 21.5681 11.9843 21.6454 12.1082 21.6981C12.2321 21.7509 12.3653 21.7781 12.5 21.7781C12.6347 21.7781 12.7679 21.7509 12.8918 21.6981C13.0157 21.6454 13.1277 21.5681 13.221 21.471L21.774 12.582L21.781 12.575C22.6248 11.6855 23.189 10.5679 23.4037 9.36078C23.6184 8.15365 23.4741 6.91005 22.9887 5.78415C22.5033 4.65826 21.6982 3.69955 20.6731 3.02689C19.648 2.35423 18.4481 1.99719 17.222 2.00002Z'
                  fill='currentColor'
                ></path>
              </svg>
            </span>
          </div>
          <div class='flex items-center justify-start space-x-1 text-sm'>
            <p class='text-neutral-900'>{formatNumberToString(product?.heart)}</p>
          </div>
        </div>
        <span class='h-[12px] w-[1px] bg-neutral-500'></span>
        <p class='text-sm text-neutral-900'>{`Đã bán ${formatNumberToString(product?.sold)}`}</p>
      </div>
    </div>
  );
};

export default SoldDetail;
