import React from "react";

const Hotline = () => {
  return (
    <div className='p-1 bg-white rounded-md shadow-md'>
      <img alt='' src='/qrcode.svg' width={72} />
    </div>
  );
};

export default Hotline;
