import React, { useMemo } from "react";
import { Space, Typography } from "antd";
import { StarFilled } from "@ant-design/icons";
import { find } from "lodash";
import SoldDetail from "../../components/SoldDetail";
import Freeship from "../../components/Freeship";
import { getDosageList, getPackingList } from "../../utils";
import { useQuery } from "react-query";
import { getAllCategory } from "../../services/categoryServices";
import { Link } from "react-router-dom";

const ProductDetail = ({ product }) => {
  const { data: categoryList, isFetching } = useQuery("getAllCategory", { queryFn: getAllCategory, enabled: true, refetchOnWindowFocus: false });

  const categories = useMemo(() => {
    if (categoryList && categoryList.length >= 0 && !isFetching) return categoryList;
  }, [categoryList, isFetching]);

  return (
    <div className='bg-white flex flex-col gap-3'>
      <h1 className='font-semibold text-[20px]'>{product?.name}</h1>
      <Space block split='・' size={1} className='text-primary'>
        <Typography className='flex text-gray-400 text-sm'>
          <span>{product?.sku}</span>
        </Typography>
        <Space className='flex text-primary text-sm'>
          <span>{product?.rating}</span>
          <StarFilled className='text-yellow-400' />
        </Space>
        <Typography className='flex text-primary text-sm'>
          <span className='mr-1'>Thương hiệu:</span>
          <span>{product?.brand}</span>
        </Typography>
      </Space>
      <SoldDetail product={product} />
      <div class='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed'></div>
      <Typography className='flex'>
        <span className='inline-block min-w-[160px] !text-lg'>Thương hiệu</span>
        <Link className='!text-lg' to={`/thuong-hieu/${product?.brand}`}>
          {product?.brand}
        </Link>
      </Typography>
      <Typography className='flex'>
        <span className='inline-block min-w-[160px] !text-lg'>Danh mục</span>
        <Link className='!text-lg' to={`/danh-muc/${product?.category}`}>
          {find(categories, (element) => element?.slug === product?.category)?.name}
        </Link>
      </Typography>
      <Typography className='flex'>
        <span className='inline-block min-w-[160px] !text-lg'>Dạng bào chế</span>
        <span className='!text-lg'>{getDosageList(Number(product?.dosageForm))?.label}</span>
      </Typography>
      <Typography className='flex'>
        <span className='inline-block min-w-[160px] !text-lg'>Quy cách</span>
        <span className='!text-lg'>{`${getPackingList(Number(product?.packing))?.label ?? ""} ${product?.quantityPerPackage ?? ""}`}</span>
      </Typography>
      <Typography className='flex'>
        <span className='inline-block min-w-[160px] !text-lg'>Nước sản xuất</span>
        <Link to={`/nuoc-san-xuat/${product?.country}`} className='!text-lg'>
          {product?.country}
        </Link>
      </Typography>
      <div class='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed'></div>
      <Typography className='flex'>
        <span className='inline-block min-w-[160px] !text-lg'>Công dụng</span>
        <span className='!text-lg'>{product?.shortDescription}</span>
      </Typography>
      <Freeship />
    </div>
  );
};

export default ProductDetail;
