import React from "react";
import { Typography, Tag, Space, Tooltip, Divider, Heading } from "antd";
import { ShoppingCartOutlined, StarFilled } from "@ant-design/icons";
import { NumericFormat } from "react-number-format";

import { formatNumberToString } from "../../utils";
import { Link } from "react-router-dom";

const Product = (props) => {
  const { product, banner } = props;
  return (
    <Link
      className='relative p-2 flex flex-col justify-between items-center rounded-xl bg-white duration-200 hover:shadow-lg'
      to={`/${product?.category}/${product?.url}`}
      href={`/${product?.category}/${product?.url}`}
    >
      {(product?.discountPercent > 0 && product?.discountPrice) && (
        <div className='bg-orange-600 absolute right-0 top-0 rounded-tr-xl rounded-bl-xl text-sm py-1 px-2 text-white font-medium'>{`- ${product?.discountPercent}%`}</div>
      )}
      <Space className='h-56'>
        <img src={product?.images?.[0]} alt='' className='max-h-56' />
      </Space>
      <Space direction='vertical' className='text-left px-2 !min-w-full'>
        <div className='flex justify-between items-end my-2'>
          <h4 className='font-bold !text-orange-600 text-md'>
            <NumericFormat value={product?.discountPrice ?? product?.salePrice} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
          </h4>
          {(product?.discountPrice || product?.discountPercent !== 0) && (
            <del className='text-gray-400 text-sm'>
              <NumericFormat value={product?.salePrice} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
            </del>
          )}
        </div>
        <Tag color='#50a199' className='font-bold'>
          {product?.brand}
        </Tag>
        <Tooltip title={product?.name} placement='bottom' color='rgba(0,0,0,0.7)'>
          <h1 className='text-ellipsis line-clamp-2 mb-1 min-h-[44px] font-semibold text-gray-600'>{product?.name}</h1>
        </Tooltip>
        <div class='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed'></div>
        <div className='flex justify-between items-center text-sm'>
          {/* <Tag color='yellow' icon={<StarFilled />} className='flex'>
            <div className='flex items-center justify-between'>
              <Typography className='text-sm'>{product?.rating}</Typography>
              <Typography className='text-sm font-[400]'>{`(${formatNumberToString(product?.heart)})`}</Typography>
            </div>
          </Tag>
          <Tag color='yellow' icon={<ShoppingCartOutlined />} className='flex text-sm'>
            <Typography className='text-sm font-[400]'>Đã bán: {formatNumberToString(product?.sold)}</Typography>
          </Tag> */}
          <div className='flex items-center justify-between'>
            {/* <Typography className='text-sm'>{product?.rating}</Typography> */}
            <Tooltip title='Đánh giá' placement='top' className='flex'>
              <Space size={1}>
                <StarFilled className='text-yellow-500 mb-[1px]' />
                <StarFilled className='text-yellow-500 mb-[1px]' />
                <StarFilled className='text-yellow-500 mb-[1px]' />
                <StarFilled className='text-yellow-500 mb-[1px]' />
                <StarFilled className='text-yellow-500 mb-[1px]' />
              </Space>
              <Divider type='vertical' />
              <Typography className='text-sm font-[400]'>{formatNumberToString(product?.heart)}</Typography>
            </Tooltip>
          </div>
          <Tooltip title='Đã bán'>
            <Typography className='text-sm font-[400]'>
              <ShoppingCartOutlined /> {formatNumberToString(product?.sold)}
            </Typography>
          </Tooltip>
        </div>
        {banner && (
          <div class='relative h-[16px] overflow-hidden rounded-xl bg-red-500/50 px-2 py-[2px] text-center text-[10px] font-semibold text-white'>
            <div class='absolute left-0 top-0 z-[1] h-full rounded-xl bg-red-500' style={{ width: "95%" }}></div>
            <div class='absolute bottom-0 left-0 right-0 top-0 z-[2] flex items-center justify-center py-[1px] text-xs'>Đang bán chạy</div>
          </div>
        )}
      </Space>
    </Link>
  );
};

export default Product;
