import React from "react";
import ReactQuill, { Quill } from "react-quill";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import { notification } from "antd";

import "react-quill/dist/quill.snow.css";
import { uploadMedia } from "../../services/uploadServices";

Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register("modules/imageUploader", ImageUploader);

const toolbar = [
  [{ font: [] }, { header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike", "blockquote", { align: [] }],
  ["link", "image", "video"],
  [{ color: [] }, { background: [] }],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["clean"],
];

const modules = {
  toolbar: {
    container: toolbar,
  },
  imageUploader: {
    upload: (file) =>
      new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
          const response = await uploadMedia(formData);
          resolve(response.url);
        } catch (error) {
          notification.error({ description: error.message || error, placement: "top" });
          reject(error);
        }
      }),
  },
  blotFormatter: {},
  clipboard: {
    matchVisual: false,
  },
};

const Markdown = ({ handleEditor, ...rest }) => {
  return <ReactQuill theme='snow' modules={modules} onChange={handleEditor} preserveWhitespace={true} {...rest} />;
};

export default Markdown;
