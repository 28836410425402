import axios from "axios";
import instance from "./config";

const instanceNoAuth = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
instanceNoAuth.interceptors.response.use((response) => response?.data);

export const createSubject = async (params) => instance.post("/subject/create-subject", params);

export const getAllSubject = async (params) => instanceNoAuth.get("/subject/get-all-subject", params);

export const updateSubject = async (params) => instance.put("/subject/update-subject", params);

export const deleteSubject = async (params) => instance.delete("/subject/delete-subject", { params });
