import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
  Upload,
  Image,
  Checkbox,
} from "antd";
import { ClusterOutlined, DeleteOutlined, EditOutlined, HomeOutlined, InboxOutlined, MoreOutlined, PlusOutlined, ScheduleOutlined } from "@ant-design/icons";
import moment from "moment";
import { find, findIndex, isArray, map } from "lodash";
import { useQuery } from "react-query";
import TextArea from "antd/es/input/TextArea";
import Link from "antd/es/typography/Link";
import { useDispatch, useSelector } from "react-redux";

import { setEditor } from "../../redux/reducer/modalReducer";
import { getBase64 } from "../../utils";
import Markdown from "../Markdown";
import { uploadMultiMedia } from "../../services/uploadServices";
import { getAllSubject } from "../../services/subjectServices";
import { createNews, deleteNews, getAllNews, updateNews } from "../../services/newsServices";

const NewsDashboard = () => {
  const { isEditor } = useSelector((state) => state?.modalReducer);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const [preview, setPreview] = useState({ isOpen: false, previewSrc: null });

  const [updating, setUpdating] = useState(false);

  const initialValuesRef = useRef({});

  const {
    data: news,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: "getAllNews",
    queryFn: getAllNews,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: subjects } = useQuery({ queryKey: "getAllSubject", queryFn: getAllSubject, enabled: true, refetchOnWindowFocus: false });

  useEffect(() => {
    document.title = "Quản lý bài viết";
  }, []);

  const handleCreateNews = () => {
    form.resetFields();
    dispatch(setEditor(false));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    initialValuesRef.current = {};
    setOpenModal(false);
  };

  const handleOpenModal = (record) => {
    initialValuesRef.current = record;
    setOpenModal(true);
    form.setFieldsValue(record);
    dispatch(setEditor(true));
  };

  const handleDeleteNews = (id) =>
    deleteNews({ id })
      .then(() => notification.success({ description: "Xóa bài viết thành công !", placement: "top", style: { width: "300px" } }))
      .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }))
      .finally(() => refetch());

  const handleOnSubmit = async (values) => {
    setUpdating(true);
    if (!isArray(values?.images)) {
      const formData = new FormData();
      for (const element of values?.images?.fileList) {
        formData.append("files", element?.originFileObj);
      }
      await uploadMultiMedia(formData)
        .then((res) => (values.images = map(res, (item) => item?.url)))
        .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }));
    }
    if (isEditor) {
      await updateNews(values)
        .then(() => {
          notification.success({ description: "Cập nhật bài viết thành công!", placement: "top", style: { width: "300px" } });
          handleCloseModal();
        })
        .catch((err) => {
          notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
        })
        .finally(() => {
          setUpdating(false);
          refetch();
        });
    } else {
      await createNews(values)
        .then(() => {
          notification.success({ description: "Tạo mới bài viết thành công!", placement: "top", style: { width: "300px" } });
          handleCloseModal();
        })
        .catch((err) => {
          notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
        })
        .finally(() => {
          setUpdating(false);
          refetch();
        });
    }
  };

  const handleOnPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({ isOpen: true, previewSrc: file.url || file.preview });
  };

  const uploadProps = {
    defaultFileList: map(initialValuesRef.current?.images ?? [], (item) => ({ url: item })),
    accept: ["image/jpg", "image/jpeg", "image/png"],
    listType: "picture-card",
    multiple: true,
    maxCount: 1,
    showUploadList: true,
    beforeUpload: () => false,
  };

  const confirm = (id) => {
    Modal.confirm({
      title: "Cảnh báo",
      content: "Xác nhận xóa bài viết?",
      cancelText: "Hủy bỏ",
      okText: "Tiếp tục",
      onOk: () => handleDeleteNews(id),
      okType: "danger",
    });
  };

  const columns = useMemo(() => {
    return [
      {
        title: "STT",
        dataIndex: "_id",
        key: "_id",
        align: "center",
        render: (value) => <Typography className='text-sm text-primary'>{findIndex(news ?? [], (item) => item?._id === value) + 1}</Typography>,
      },
      {
        title: "Tên bài viết",
        dataIndex: "title",
        key: "title",
        align: "center",
        width: 650,
        filters: map(news, (item) => ({ value: item?.title, text: item?.title })),
        onFilter: (value, record) => record.title.startsWith(value),
        filterSearch: true,
        render: (value, record) => (
          <div className='text-left'>
            <Space
              className='cursor-pointer text-left'
              align='center'
              size={0}
              split={<MoreOutlined className='opacity-30' />}
              onClick={() => handleOpenModal(record)}
            >
              <Avatar src={record?.images?.[0]} shape='square' icon={<InboxOutlined />} />
              <Tooltip title={value}>
                <Link color='#50a199' className='text-sm text-left !line-clamp-1'>
                  {value}
                </Link>
              </Tooltip>
            </Space>
          </div>
        ),
      },
      {
        title: "Danh mục",
        width: 180,
        align: "center",
        dataIndex: "subject",
        key: "subject",
        filters: map(subjects, (item) => ({ value: item?.slug, text: item?.name })),
        onFilter: (value, record) => record.subject.startsWith(value),
        filterSearch: true,
        render: (value, record) => <Typography className='text-left'>{find(subjects, (item) => item?.slug === value)?.name}</Typography>,
      },
      {
        title: "Mô tả ngắn",
        align: "center",
        width: 650,
        dataIndex: "description",
        key: "description",
        render: (value, record) => (
          <Tooltip title={value}>
            <Typography className='line-clamp-1 text-left'>{value}</Typography>
          </Tooltip>
        ),
      },
      {
        title: "Ngày đăng",
        dataIndex: "createDate",
        align: "center",
        width: 150,
        key: "createDate",
        sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
        render: (record) => moment(record).format("DD/MM/YYYY"),
      },
      {
        title: "Tùy chọn",
        width: 150,
        align: "center",
        render: (record) => (
          <Space size={5}>
            <Tooltip title={"Chỉnh sửa"}>
              <Button onClick={() => handleOpenModal(record)} icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button onClick={() => confirm(record?._id)} icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news, subjects]);

  const dataSourceFn = useCallback(
    (data) => {
      if (isArray(data)) {
        return map(data, (item, index) => ({
          key: index,
          _id: item?._id,
          name: item?.name,
          createDate: item?.createdAt,
          ...item,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching]
  );

  return (
    <Space>
      <Space direction='vertical' className='p-3'>
        <Breadcrumb
          items={[
            {
              href: "/dashboard",
              title: <HomeOutlined />,
            },
            {
              href: "",
              title: "Bài viết",
            },
            {
              title: "Tất cả bài viết",
            },
          ]}
        />
        <Space direction='vertical'>
          <Typography.Title level={4} className='my-4'>
            Bài viết
          </Typography.Title>
        </Space>
        <Button type='primary' className='mb-3' onClick={handleCreateNews}>
          Tạo mới
        </Button>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={dataSourceFn(news) ?? []}
          size='small'
          bordered
          pagination={{ size: "default", pageSize: 20 }}
        />
      </Space>

      <Modal
        open={openModal}
        keyboard
        maskClosable
        title='Chỉnh sửa bài viết'
        width={850}
        okText={isEditor ? "Chỉnh sửa" : "Tạo mới"}
        cancelText='Huỷ bỏ'
        onCancel={handleCloseModal}
        confirmLoading={updating}
        okButtonProps={{
          htmlType: "submit",
          form: "newsForm",
        }}
      >
        <Form id='newsForm' form={form} onFinish={handleOnSubmit} spellCheck={false} labelCol={{ span: 24 }} wrapperCol={24}>
          <Row gutter={50}>
            <Col span={24}>
              <Row justify={"start"} gutter={12}>
                <Col span={24}>
                  <Form.Item
                    name='title'
                    label='Tiêu đề bài viết'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tiêu đề bài viết!",
                      },
                    ]}
                  >
                    <Input
                      count={{
                        show: true,
                        max: 255,
                      }}
                      placeholder='Tiêu đề bài viết'
                      allowClear={true}
                      prefix={<ScheduleOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Chuyên mục'
                    name='subject'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn chuyên mục!",
                      },
                    ]}
                  >
                    <Select
                      placeholder='Chọn chuyên mục'
                      allowClear={true}
                      showSearch={true}
                      virtual={false}
                      optionFilterProp='label'
                      suffixIcon={<ClusterOutlined />}
                      options={map(subjects, (item) => ({ label: item?.name, value: item?.slug }))}
                    />
                  </Form.Item>
                  <Form.Item name='isSpecial' valuePropName='checked' className='flex border px-3 rounded-md w-full' label='Hiển thị lên bài viết chính'>
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name='description'
                    label='Mô tả ngắn'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mô tả ngắn!",
                      },
                    ]}
                  >
                    <TextArea placeholder='Nhập mô tả ngắn của bài viết' rows={5} allowClear={true} />
                  </Form.Item>
                </Col>
                <Col span={0} hidden>
                  <Form.Item name='_id'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
                  <Form.Item
                    name='url'
                    label='Đường dẫn'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập đường dẫn bài viết!",
                      },
                    ]}
                  >
                    <Input
                      count={{show: true}}
                      placeholder='Đường dẫn bài viết'
                      allowClear={true}
                      prefix={<ScheduleOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
            <Col span={24} className='flex'>
              <Row>
                <Col align='center' span={24}>
                  <Form.Item name='images'>
                    <Upload {...uploadProps} onPreview={handleOnPreview} key={initialValuesRef.current?._id}>
                      <div>
                        <PlusOutlined />
                        <p className='pt-2 font-Poppins'>Ảnh đại diện</p>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item label='Nội dung bài viết' name='content'>
                <Markdown />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Image
          preview={{
            visible: preview.isOpen,
            src: preview.previewSrc,
            onVisibleChange: (value) => setPreview({ isOpen: value, previewSrc: null }),
          }}
        />
      </Modal>
    </Space>
  );
};

export default NewsDashboard;
