import axios from "axios";
import instance from "./config";

const instanceNoAuth = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
instanceNoAuth.interceptors.response.use((response) => response?.data);

export const createProduct = async (params) => instance.post("/product/create-product", params);

export const getAllProduct = async (params) => instanceNoAuth.get("/product/get-all-product", params);

export const deleteProduct = async (params) => instance.delete("/product/delete-product", { params });

export const updateProduct = async (params) => instance.put("/product/update-product", params);

export const findProduct = async (params) => instanceNoAuth.get("/product/search", params);
