import React, { useMemo } from "react";
import { Tag, Button } from "antd";
import Countdown from "react-countdown";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import Product from "../Product";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { map } from "lodash";
import { useQuery } from "react-query";
import { getAllProduct } from "../../services/productServices";

const FlashSale = () => {
  const { data: productList, isFetching } = useQuery("getAllProductFlashSale", {
    queryFn: async () => {
      const response = await getAllProduct({ params: { isFlashSale: true } });
      return response?.data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });
  const deadline = Date.now() + 1000 * 60 * 60 * 24 * 1.5 + 1000 * 25;

  const products = useMemo(() => {
    if (productList && productList?.length > 0) return productList;
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList, isFetching]);

  return (
    <div className='relative wide mx-auto flex flex-col py-8 pt-4 shadow bg-[#FCF3E8] rounded-xl group lg:px-8 px-3 md:px-3'>
      <div className='mb-6'>
        <img className='absolute top-[-10px] left-[50%] translate-x-[-50%]' src='/ban_chay.png' alt='' />
        <p className='absolute top-[-5px] left-[50%] translate-x-[-50%] text-white font-bold'>Sản phẩm bán chạy</p>
        <img src='/flashsale.svg' alt='' width={150} className='mb-5 lg:mt-0 mt-12' />
        <Countdown
          date={deadline}
          daysInHours={true}
          renderer={({ days, hours, minutes, seconds }) => (
            <>
              <Tag color='black' className='py-2 font-bold text-xl m-0'>
                <span>{days > 9 ? days : `0${days}`}</span>
              </Tag>
              <span className='py-2 mx-2 font-bold text-xl'>:</span>
              <Tag color='black' className='py-2 font-bold text-xl m-0'>
                <span>{hours > 9 ? hours : `0${hours}`}</span>
              </Tag>
              <span className='py-2 mx-2 font-bold text-xl'>:</span>
              <Tag color='black' className='py-2 font-bold text-xl m-0'>
                <span>{minutes > 9 ? minutes : `0${minutes}`}</span>
              </Tag>
              <span className='py-2 mx-2 font-bold text-xl'>:</span>
              <Tag color='black' className='py-2 font-bold text-xl m-0'>
                <span>{seconds > 9 ? seconds : `0${seconds}`}</span>
              </Tag>
            </>
          )}
        />
      </div>
      <Swiper
        slidesPerView={5}
        spaceBetween={12}
        slidesPerGroup={1}
        loop={true}
        autoplay={{ pauseOnMouseEnter: true, delay: 5000 }}
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
        }}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          639: {
            slidesPerView: 2,
          },
          769: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
          },
        }}
        modules={[Navigation, Autoplay]}
        className='mySwiper'
      >
        {map(products, (elm) => (
          <SwiperSlide className='rounded-lg'>
            <Product product={elm} banner={true} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button className='absolute right-4 top-[50%] button-next z-10 group-hover:scale-125 group-hover:opacity-100 opacity-0 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
        <RightOutlined className='text-primary' />
      </Button>
      <Button className='absolute left-4 top-[50%] button-prev z-10 group-hover:scale-125 group-hover:opacity-100 opacity-0 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
        <LeftOutlined className='text-primary' />
      </Button>
    </div>
  );
};

export default FlashSale;
