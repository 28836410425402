import instance from "./config";
import { loginFailed, loginStart, loginSuccess, logoutFailed, logoutStart, logoutSuccess } from "../redux/reducer/authReducer";
import axios from "axios";

export const createUser = async (params) => instance.post("/user/register", params);

export const loginUser = async (params, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await axios.post("/user/login", params, { baseURL: process.env.REACT_APP_BACKEND_URL, withCredentials: true });
    dispatch(loginSuccess(res?.data));
    return res?.data;
  } catch (error) {
    dispatch(loginFailed());
    return error;
  }
};

export const logoutUser = async (params, dispatch) => {
  dispatch(logoutStart());
  try {
    const res = await instance.post("/user/logout", params, { baseURL: process.env.REACT_APP_BACKEND_URL, withCredentials: true });
    dispatch(logoutSuccess());
    return res;
  } catch (error) {
    dispatch(logoutFailed());
    return error;
  }
};

export const getUser = async (params) => instance.get("/user/get-user", { params });

export const deleteUser = async (params) => await instance.delete("/user/delete-user", { params });

export const updateUser = async (params) => await instance.put("/user/update-user", params);

export const getAllUser = async (params) => await instance.get("/user/get-all-user", params);
