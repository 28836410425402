import React, { Fragment, useEffect, useMemo } from "react";
import Post from "../components/Post";
import TopPost from "../components/TopPost";
import { Breadcrumb, Button, Col, Row, Space, Typography } from "antd";
import { useQuery } from "react-query";
import { getAllNews } from "../../src/services/newsServices";
import { getAllSubject } from "../../src/services/subjectServices";
import { filter, find, map } from "lodash";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";

const NewsPage = () => {
  const { data: news } = useQuery({ queryKey: "getAllNews", queryFn: getAllNews, enabled: true, refetchOnWindowFocus: false });

  const { data: subjects } = useQuery({ queryKey: "getAllSubject", queryFn: getAllSubject, enabled: true, refetchOnWindowFocus: false });

  useEffect(() => {
    document.title = "Góc tin tức - Healthymall.vn thực phẩm chức năng chính hãng từ Úc";
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const posts = useMemo(() => {
    if (news && news?.length >= 0) {
      const formattedNews = map(news, (item) => ({
        ...item,
        subject: find(subjects, (elm) => elm?.slug === item?.subject)?.name,
      }));
      return formattedNews;
    }
  }, [news, subjects]);

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            title: "Trang bài viết",
          },
        ]}
      />
      <div className='bg-white pt-3'>
        <div className='wide'>
          <Typography.Title level={3} className='mb-2'>
            Bài viết nổi bật
          </Typography.Title>
          <Space className='mb-2 mt-0'>
            {map(subjects, (item) => (
              <Link to={`/chuyen-muc/${item?.slug}`}>
                <Button className='rounded-full'>{item?.name}</Button>
              </Link>
            ))}
          </Space>
          <Row gutter={24}>
            <Col span={24}>
              <TopPost primaryPost={find(posts, (item) => item?.isSpecial)} sidePosts={posts} />
            </Col>
          </Row>
        </div>
      </div>
      <div className='wide mt-6'>
        <Row gutter={24}>
          <Col span={18}>
            <div className='flex flex-col gap-5'>
              {map(subjects, (item, index) => (
                <Post key={index} posts={filter(posts, (post) => post?.subject === item?.name)?.slice(0, 4)} subject={item} />
              ))}
            </div>
          </Col>
          <Col span={6} className='bg-white shadow-sm flex flex-col gap-3 p-3 rounded-md'>
            <Typography.Title level={4} className='m-3'>
              Chuyên mục nổi bật
            </Typography.Title>
            {map(subjects, (subject) => (
              <Link to={`/chuyen-muc/${subject?.slug}`}>
                <div className='flex items-center gap-3 p-2 rounded-lg' style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
                  <div
                    className='!w-[64px] !h-[64px]'
                    style={{
                      backgroundImage: `url(${subject?.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  />
                  <div className='flex-[0.9]'>
                    <Typography className='line-clamp-1 font-semibold'>{subject?.name}</Typography>
                    <Typography className='text-sm font-light line-clamp-1'>{subject?.description}</Typography>
                  </div>
                </div>
              </Link>
            ))}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NewsPage;
