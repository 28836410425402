import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { loginSuccess } from "../redux/reducer/authReducer";
import { isString } from "lodash";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const refreshToken = async () => {
  try {
    const res = await axios.get("/refresh-token", { baseURL: process.env.REACT_APP_BACKEND_URL, withCredentials: true });
    return res;
  } catch (error) {
    console.log(error);
  }
};

instance.interceptors.request.use(async (request) => {
  const currentUser = window?.state?.getState()?.authReducer?.login?.currentUser;
  const tokenDecoded = isString(currentUser?.accessToken) ? jwtDecode(currentUser?.accessToken) : "";

  if (tokenDecoded?.exp < Date.now() / 1000) {
    const res = await refreshToken();
    request.auth = {
      token: res?.data?.accessToken,
    };
    window.state.dispatch(
      loginSuccess({
        ...currentUser,
        accessToken: res?.data?.accessToken,
      })
    );
    // window.location.reload();
  }
  request.headers.token = `Bearer ${window.state.getState().authReducer?.login?.currentUser?.accessToken}`;
  return request;
});

instance.interceptors.response.use((response) => response?.data);

export default instance;
