import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import categoryReducer from "./categoryReducer";
import cartReducer from "./cartReducer";
import recentProductReducer from "./recentProductReducer";
import imageReducer from "./imageReducer";
import recentKeywordReducer from "./recentKeywordReducer";

const rootReducer = combineReducers({
  authReducer,
  modalReducer,
  categoryReducer,
  cartReducer,
  recentProductReducer,
  imageReducer,
  recentKeywordReducer,
});

export default rootReducer;
