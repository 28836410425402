import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";

import Category from "./Category";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useQuery } from "react-query";
import { getAllCategory } from "../../services/categoryServices";
import { map } from "lodash";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState(null);
  const { data, isFetching } = useQuery("getAllCategory", { queryFn: getAllCategory, refetchOnWindowFocus: false, enabled: true });

  useEffect(() => {
    if (!isFetching) setCategories(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <div className='wide mx-auto my-5 group'>
      <Swiper
        style={{ paddingTop: "12px", paddingBottom: "12px" }}
        slidesPerView={5}
        spaceBetween={12}
        loop={true}
        navigation={{
          nextEl: ".button-next-elm",
          prevEl: ".button-prev-elm",
        }}
        freeMode={true}
        modules={[Navigation, FreeMode]}
        className='mySwiper p-3 lg:px-0'
        breakpoints={
          // when window width is >= 320px
          {
            320: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
              spaceBetween: 12,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 5,
              spaceBetween: 12,
            },
          }
        }
      >
        {map(categories, (category) => (
          <SwiperSlide>
            <Link to={`/danh-muc/${category?.slug}`}>
              <Category title={category?.name} image={category?.image} description={category?.description} />
            </Link>
          </SwiperSlide>
        ))}
        <Button className='absolute right-3 top-[50%] translate-y-[-50%] scale-125 opacity-0 group-hover:opacity-100 button-next-elm z-10 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
          <RightOutlined className='text-primary' />
        </Button>
        <Button className='absolute left-3 top-[50%] translate-y-[-50%] scale-125 opacity-0 group-hover:opacity-100 button-prev-elm z-10 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-white'>
          <LeftOutlined className='text-primary' />
        </Button>
      </Swiper>
    </div>
  );
};

export default Categories;
