import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Button, Modal, Tag } from "antd";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const Preview = ({ product }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className='relative bg-white flex flex-col group'>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        navigation={{
          nextEl: ".button-next-preview-large",
          prevEl: ".button-prev-preview-large",
        }}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className='mySwiper2 select-none rounded-md p-5'
        onClick={() => setOpenModal(true)}
      >
        {(product?.images ?? []).map((item) => (
          <SwiperSlide className='cursor-pointer'>
            <img src={item} alt='' className='aspect-square' />
          </SwiperSlide>
        ))}
      </Swiper>
      <Tag color='yellow' className='my-4 p-0 mx-0 text-center w-full'>
        Sản phẩm 100% nhập khẩu chính hãng từ Úc
      </Tag>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={12}
        slidesPerView={5}
        freeMode={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className='mySwiper h-20 w-16 select-none'
      >
        {(product?.images ?? []).map((item) => (
          <SwiperSlide className='rounded-xl border p-2 h-20 w-16 cursor-pointer'>
            <img src={item} alt='' className='aspect-square' />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        className={`absolute right-8 top-[45%] translate-y-[-50%] button-next-preview-large z-10 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-[rgba(0,0,0,0.3)] outline-none border-none group-hover:opacity-100 group-hover:scale-110 opacity-0`}
      >
        <RightOutlined className='text-white' />
      </Button>
      <Button
        className={`absolute left-8 top-[45%] translate-y-[-50%] button-prev-preview-large z-10 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-[rgba(0,0,0,0.3)] outline-none border-none group-hover:opacity-100 group-hover:scale-110 opacity-0`}
      >
        <LeftOutlined className='text-white' />
      </Button>
      <Modal open={openModal} centered width={700} footer={null} onCancel={() => setOpenModal(false)}>
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          navigation={{
            nextEl: ".button-next-detail-large",
            prevEl: ".button-prev-detail-large",
          }}
          thumbs={{ swiper: thumbsSwiper1 && !thumbsSwiper1.destroyed ? thumbsSwiper1 : null }}
          modules={[FreeMode, Navigation, Thumbs]}
          className='mySwiper3 mb-8 p-3 select-none'
          onClick={() => setOpenModal(true)}
        >
          {(product?.images ?? []).map((item) => (
            <SwiperSlide className='cursor-pointer'>
              <img src={item} alt='' className='aspect-square' />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper1}
          spaceBetween={12}
          slidesPerView={5}
          freeMode={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className='mySwiper1 h-32 select-none'
        >
          {(product?.images ?? []).map((item) => (
            <SwiperSlide className='rounded-xl border p-2 h-32 cursor-pointer'>
              <img src={item} alt='' className='aspect-square' />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          className={`absolute right-14 top-[40%] translate-y-[-50%] button-next-detail-large z-10 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-[rgba(0,0,0,0.3)] outline-none border-none scale-125`}
        >
          <RightOutlined className='text-white' />
        </Button>
        <Button
          className={`absolute left-14 top-[40%] translate-y-[-50%] button-prev-detail-large z-10 flex justify-center items-center duration-300 rounded-full w-9 h-9 bg-[rgba(0,0,0,0.3)] outline-none border-none scale-125`}
        >
          <LeftOutlined className='text-white' />
        </Button>
      </Modal>
    </div>
  );
};

export default Preview;
