import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
  Upload,
  Image,
  InputNumber,
  Checkbox,
} from "antd";
import {
  BarcodeOutlined,
  ClusterOutlined,
  CrownOutlined,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  FileZipOutlined,
  GlobalOutlined,
  HomeOutlined,
  HourglassOutlined,
  InboxOutlined,
  MoreOutlined,
  PercentageOutlined,
  PlusOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { find, findIndex, isArray, map } from "lodash";
import { useQuery } from "react-query";
import { NumericFormat } from "react-number-format";
import TextArea from "antd/es/input/TextArea";
import Link from "antd/es/typography/Link";
import { useDispatch, useSelector } from "react-redux";

import { setEditor } from "../../redux/reducer/modalReducer";
import * as constant from "../../data/constant";
import { setCategories } from "../../redux/reducer/categoryReducer";
import { getAllCategory } from "../../services/categoryServices";
import { getAllBrand } from "../../services/brandServices";
import { createProduct, deleteProduct, getAllProduct, updateProduct } from "../../services/productServices";
import { formatterNumber, getBase64, getDiscountPercent, getDiscountPrice, getDosageList, getPackingList, parserNumber } from "../../utils";
import Markdown from "../Markdown";
import { uploadMultiMedia } from "../../services/uploadServices";

const ProductsDashboard = () => {
  const { isEditor } = useSelector((state) => state?.modalReducer);

  const { categories } = useSelector((state) => state?.categoryReducer);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const [preview, setPreview] = useState({ isOpen: false, previewSrc: null });

  const [updating, setUpdating] = useState(false);

  const initialValuesRef = useRef({});

  const {
    data: products,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: "getAllProduct",
    queryFn: async () => {
      const response = await getAllProduct();
      return response?.data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: brands } = useQuery({ queryKey: "getAllBrand", queryFn: getAllBrand, enabled: true, refetchOnWindowFocus: false });

  useEffect(() => {
    document.title = "Quản lý sản phẩm";
  }, []);

  const { data: category, isFetching: isFetchingCategories } = useQuery({
    queryKey: "getAllCategory",
    queryFn: getAllCategory,
    enabled: true,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!isFetchingCategories) {
      dispatch(setCategories(category));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingCategories]);

  const handleCreateProduct = () => {
    form.resetFields();
    dispatch(setEditor(false));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    initialValuesRef.current = {};
    setOpenModal(false);
  };

  const handleOpenModal = (record) => {
    initialValuesRef.current = record;
    setOpenModal(true);
    form.setFieldsValue(record);
    dispatch(setEditor(true));
  };

  const handleDeleteProduct = (id) =>
    deleteProduct({ id })
      .then(() => notification.success({ description: "Xóa sản phẩm thành công !", placement: "top", style: { width: "300px" } }))
      .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }))
      .finally(() => refetch());

  const handleOnSubmit = async (values) => {
    setUpdating(true);
    if (!isArray(values?.images)) {
      const formData = new FormData();
      for (const element of values?.images?.fileList) {
        formData.append("files", element?.originFileObj);
      }
      await uploadMultiMedia(formData)
        .then((res) => (values.images = map(res, (item) => item?.url)))
        .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }));
    }
    if (isEditor) {
      await updateProduct(values)
        .then(() => {
          notification.success({ description: "Cập nhật sản phẩm thành công!", placement: "top", style: { width: "300px" } });
          handleCloseModal();
        })
        .catch((err) => {
          notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
        })
        .finally(() => {
          setUpdating(false);
          refetch();
        });
    } else {
      await createProduct(values)
        .then(() => {
          notification.success({ description: "Tạo mới sản phẩm thành công!", placement: "top", style: { width: "300px" } });
          handleCloseModal();
        })
        .catch((err) => {
          notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
        })
        .finally(() => {
          setUpdating(false);
          refetch();
        });
    }
  };

  const handleOnPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({ isOpen: true, previewSrc: file.url || file.preview });
  };

  const uploadProps = {
    defaultFileList: map(initialValuesRef.current?.images ?? [], (item) => ({ url: item })),
    accept: ["image/jpg", "image/jpeg", "image/png"],
    listType: "picture-card",
    multiple: true,
    maxCount: 8,
    showUploadList: true,
    beforeUpload: () => false,
  };

  const confirm = (id) => {
    Modal.confirm({
      title: "Cảnh báo",
      content: "Xác nhận xóa sản phẩm?",
      cancelText: "Hủy bỏ",
      okText: "Tiếp tục",
      onOk: () => handleDeleteProduct(id),
      okType: "danger",
    });
  };

  const columns = useMemo(() => {
    return [
      {
        title: "STT",
        dataIndex: "_id",
        key: "_id",
        align: "center",
        render: (value) => <Typography className='text-sm text-primary'>{findIndex(products ?? [], (item) => item?._id === value) + 1}</Typography>,
      },
      {
        title: "Tên sản phẩm",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 650,
        filters: map(products, (item) => ({ value: item?.name, text: item?.name })),
        onFilter: (value, record) => record.name.startsWith(value),
        filterSearch: true,
        render: (value, record) => (
          <div className='text-left'>
            <Space
              className='cursor-pointer text-left'
              align='center'
              size={0}
              split={<MoreOutlined className='opacity-30' />}
              onClick={() => handleOpenModal(record)}
            >
              <Avatar src={record?.images?.[0]} shape='square' icon={<InboxOutlined />} />
              <Tooltip title={value}>
                <Link color='#50a199' className='text-sm text-left !line-clamp-1'>
                  {value}
                </Link>
              </Tooltip>
            </Space>
          </div>
        ),
      },
      {
        title: "Mã sản phẩm",
        align: "left",
        width: 150,
        dataIndex: "sku",
        key: "sku",
        filters: map(products, (item) => ({ value: item?.sku, text: item?.sku })),
        onFilter: (value, record) => record.sku.startsWith(value),
        filterSearch: true,
        render: (value, record) => (
          <Space className='cursor-pointer' align='center' size={0} onClick={() => handleOpenModal(record)}>
            <Tag color='#50a199' bordered={false} className='text-sm'>
              {value}
            </Tag>
          </Space>
        ),
      },
      {
        title: "Thương hiệu",
        align: "left",
        width: 150,
        dataIndex: "brand",
        key: "brand",
        filters: map(brands, (item) => ({ value: item?.name, text: item?.name })),
        onFilter: (value, record) => record.brand.startsWith(value),
        filterSearch: true,
        render: (value, record) => <Typography className='font-medium'>{value}</Typography>,
      },
      {
        title: "Danh mục",
        width: 150,
        dataIndex: "category",
        key: "category",
        filters: map(categories, (item) => ({ value: item?.slug, text: item?.name })),
        onFilter: (value, record) => record.category.startsWith(value),
        filterSearch: true,
        render: (value, record) => <Typography>{find(categories, (item) => item?.slug === value)?.name}</Typography>,
      },
      {
        title: "Tồn kho",
        align: "right",
        width: 150,
        dataIndex: "stock",
        key: "stock",
        sorter: (a, b) => a.stock - b.stock,
        render: (value, record) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' />,
      },
      {
        title: "Đã bán",
        align: "right",
        width: 150,
        dataIndex: "sold",
        key: "sold",
        render: (value, record) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' />,
      },
      {
        title: "Giá gốc",
        align: "right",
        width: 150,
        dataIndex: "salePrice",
        key: "salePrice",
        sorter: (a, b) => a.salePrice - b.salePrice,
        render: (value, record) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Khuyến mãi",
        align: "right",
        width: 150,
        dataIndex: "discountPrice",
        key: "discountPrice",
        sorter: (a, b) => a.discountPrice - b.discountPrice,
        render: (value, record) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Ngày tạo",
        dataIndex: "createDate",
        align: "center",
        width: 150,
        key: "createDate",
        sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
        render: (record) => moment(record).format("DD/MM/YYYY"),
      },
      {
        title: "Tùy chọn",
        width: 150,
        align: "center",
        render: (record) => (
          <Space size={5}>
            <Tooltip title={"Chỉnh sửa"}>
              <Button onClick={() => handleOpenModal(record)} icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button onClick={() => confirm(record?._id)} icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const dataSourceFn = useCallback(
    (data) => {
      if (isArray(data)) {
        return map(data, (item, index) => ({
          key: index,
          _id: item?._id,
          name: item?.name,
          createDate: item?.createdAt,
          ...item,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching]
  );

  return (
    <Space>
      <Space direction='vertical' className='p-3'>
        <Breadcrumb
          items={[
            {
              href: "/dashboard",
              title: <HomeOutlined />,
            },
            {
              href: "",
              title: <span>{constant.PRODUCT.TITLE}</span>,
            },
            {
              title: <span>Tất cả sản phẩm</span>,
            },
          ]}
        />
        <Space direction='vertical'>
          <Typography.Title level={4} className='my-4'>
            {constant.PRODUCT.TITLE}
          </Typography.Title>
        </Space>
        <Button type='primary' className='mb-3' onClick={handleCreateProduct}>
          Tạo mới
        </Button>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={dataSourceFn(products) ?? []}
          size='small'
          bordered
          pagination={{ size: "default", pageSize: 20 }}
        />
      </Space>

      <Modal
        open={openModal}
        keyboard
        maskClosable
        title='Chỉnh sửa sản phẩm'
        width={950}
        okText={isEditor ? "Chỉnh sửa" : "Tạo mới"}
        cancelText='Huỷ bỏ'
        // forceRender
        onCancel={handleCloseModal}
        confirmLoading={updating}
        okButtonProps={{
          htmlType: "submit",
          form: "productForm",
        }}
      >
        <Form id='productForm' form={form} onFinish={handleOnSubmit} spellCheck={false} labelCol={{ span: 24 }} wrapperCol={24}>
          <Row gutter={50}>
            <Col span={24}>
              <Row justify={"center"} gutter={12}>
                <Col span={24}>
                  <Form.Item
                    name='name'
                    label='Tên sản phẩm'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên sản phẩm!",
                      },
                    ]}
                  >
                    <Input
                      count={{
                        show: true,
                        max: 255,
                      }}
                      placeholder='Tên sản phẩm'
                      allowClear={true}
                      prefix={<ScheduleOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Mã sản phẩm'
                    name='sku'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mã sản phẩm!",
                      },
                    ]}
                  >
                    <Input placeholder='Mã sản phẩm' allowClear={true} prefix={<BarcodeOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Thương hiệu'
                    name='brand'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn thương hiệu!",
                      },
                    ]}
                  >
                    <Select
                      placeholder='Chọn thương hiệu'
                      allowClear={true}
                      showSearch={true}
                      virtual={false}
                      optionFilterProp='label'
                      suffixIcon={<CrownOutlined />}
                      options={map(brands, (item) => ({ label: item?.name, value: item?.name }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Danh mục'
                    name='category'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn danh mục!",
                      },
                    ]}
                  >
                    <Select
                      placeholder='Chọn danh mục'
                      allowClear={true}
                      showSearch={true}
                      virtual={false}
                      optionFilterProp='label'
                      suffixIcon={<ClusterOutlined />}
                      options={map(categories, (item) => ({ label: item?.name, value: item?.slug }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='salePrice'
                    label='Giá gốc'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập giá gốc!",
                      },
                    ]}
                  >
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Nhập giá gốc'
                      controls={false}
                      allowClear={true}
                      onBlur={() =>
                        form.setFieldValue(
                          "discountPercent",
                          form.getFieldValue("salePrice") && form.getFieldValue("discountPrice")
                            ? getDiscountPercent(form.getFieldValue("salePrice"), form.getFieldValue("discountPrice"))
                            : 0
                        )
                      }
                      prefix={<DollarOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='discountPrice'
                    label='Giá khuyến mãi'
                  >
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Nhập giá khuyến mãi'
                      controls={false}
                      allowClear={true}
                      onChange={(val) => {
                        if (val > 100) {
                          form.setFieldValue("discountPercent", 100);
                        }
                        if (val < 0) {
                          form.setFieldValue("discountPercent", 0);
                        }
                      }}
                      onBlur={() =>
                        form.setFieldValue(
                          "discountPercent",
                          form.getFieldValue("salePrice") && form.getFieldValue("discountPrice")
                            ? getDiscountPercent(form.getFieldValue("salePrice"), form.getFieldValue("discountPrice"))
                            : 0
                        )
                      }
                      prefix={<DollarOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name='discountPercent' label='Chiết khấu'>
                    <InputNumber
                      className='w-full'
                      placeholder='Tỉ lệ chiết khấu'
                      controls={false}
                      // disabled
                      onBlur={(e) =>
                        form.setFieldValue(
                          "discountPrice",
                          form.getFieldValue("salePrice") && Number(e.target.value) >= 0 && Number(e.target.value) <= 100
                            ? getDiscountPrice(form.getFieldValue("salePrice"), e.target.value)
                            : 0
                        )
                      }
                      allowClear={true}
                      min={0}
                      max={100}
                      prefix={<PercentageOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Số lượng tồn kho' name='stock'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Nhập số lượng tồn kho'
                      controls={false}
                      allowClear={true}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Số lượng đã bán' name='sold'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Nhập số lượng đã bán'
                      controls={false}
                      allowClear={true}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Số lượt thích sản phẩm' name='heart'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Nhập số lượt thích sản phẩm'
                      controls={false}
                      allowClear={true}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='country'
                    label='Nước sản xuất'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập nước sản xuất!",
                      },
                    ]}
                  >
                    <Input placeholder='Nước sản xuất' allowClear={true} prefix={<GlobalOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Hình thức sản phẩm'
                    name='dosageForm'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn hình thức sản phẩm!",
                      },
                    ]}
                  >
                    <Select
                      placeholder='Chọn hình thức sản phẩm'
                      allowClear={true}
                      showSearch={true}
                      optionFilterProp='label'
                      suffixIcon={<HourglassOutlined />}
                      options={getDosageList()}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Quy cách đóng gói'
                    name='packing'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng quy cách đóng gói!",
                      },
                    ]}
                  >
                    <Select
                      placeholder='Chọn quy cách đóng gói'
                      allowClear={true}
                      showSearch={true}
                      optionFilterProp='label'
                      suffixIcon={<FileZipOutlined />}
                      options={getPackingList()}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='quantityPerPackage'
                    label='Số lượng đóng gói'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số lượng đóng gói!",
                      },
                    ]}
                  >
                    <Input placeholder='VD: 60 Viên, 200ml' allowClear={true} prefix={<EditOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name='shortDescription'
                    label='Mô tả ngắn'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mô tả ngắn!",
                      },
                    ]}
                  >
                    <TextArea placeholder='Nhập mô tả ngắn, công dụng sản phẩm' rows={1} allowClear={true} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                <Form.Item
                    name='url'
                    label='Đường dẫn'
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập đường dẫn!",
                      },
                    ]}
                  >
                    <Input
                      count={{show: true}}
                      placeholder='Đường dẫn sản phẩm'
                      allowClear={true}
                      prefix={<ScheduleOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item></Col>
                <Col span={8}>
                  <Form.Item name='isFlashSale' valuePropName='checked' className='border px-3 rounded-lg w-full' label='Hiển thị lên ưu đãi ở trang chủ'>
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name='isPreOrder' valuePropName='checked' className='border px-3 rounded-lg w-full' label='Hàng đặt trước (pre-order)'>
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name='isStop' valuePropName='checked' className='border px-3 rounded-lg w-full' label='Đang tạm dừng mẫu'>
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col span={0} hidden>
                  <Form.Item name='_id'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24} className='flex'>
              <Row>
                <Col align='center' span={24}>
                  <Form.Item name='images'>
                    <Upload {...uploadProps} onPreview={handleOnPreview} key={initialValuesRef.current?._id}>
                      <div>
                        <PlusOutlined />
                        <p className='pt-2 font-Poppins'>Tải ảnh lên</p>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item label='Mô tả sản phẩm' name='description'>
                <Markdown />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Image
          preview={{
            visible: preview.isOpen,
            src: preview.previewSrc,
            onVisibleChange: (value) => setPreview({ isOpen: value, previewSrc: null }),
          }}
        />
      </Modal>
    </Space>
  );
};

export default ProductsDashboard;
