import { HomeOutlined, UploadOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, ColorPicker, Divider, Form, Image, Input, Row, Space, Tag, Typography, Upload, notification } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { createBanner, createSlider, getBanner, getSlider } from "../../services/flieServices";
import { useQuery } from "react-query";
import { find, isEmpty } from "lodash";

const FileManagerDashboard = () => {
  const [updating, setUpdating] = useState(false);

  const [banners, setBanners] = useState([]);

  const [sliders, setSliders] = useState([]);

  const [forceRender, setForceRender] = useState(false);

  const [color, setColor] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Quản lý hình ảnh";
  }, []);

  const { data: bannerList, isSuccess, refetch: refetchBannerFn } = useQuery({ queryKey: "getBanners", queryFn: getBanner, refetchOnWindowFocus: true });

  const {
    data: sliderList,
    isSuccess: getSliderSuccess,
    refetch: refetchSliderFn,
  } = useQuery({ queryKey: "getSliders", queryFn: getSlider, refetchOnWindowFocus: true });

  useEffect(() => {
    if (isSuccess && !isEmpty(bannerList)) {
      const bannerSearch = find(bannerList, (item) => item?.name === "banner_search");
      form.setFieldValue("title", bannerSearch?.title);
      form.setFieldValue("description", bannerSearch?.description);
      setBanners(bannerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, bannerList, forceRender]);

  useEffect(() => {
    if (getSliderSuccess && !isEmpty(sliderList)) {
      setSliders(sliderList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSliderSuccess, forceRender]);

  const handleOnSubmit = async (value) => {
    const name = Object.keys(value)?.[0];
    const url = value?.[name]?.file?.response?.url;

    if (name.includes("banner")) {
      await createBanner({ url, name, color })
        .then(() => notification?.success({ message: "Thành công!" }))
        .catch((err) => notification?.error({ message: err?.message || err }))
        .finally(() => setUpdating(false));
    } else if (name.includes("slider")) {
      await createSlider({ url, name })
        .then(() => {
          notification?.success({ message: "Thành công!" });
          setForceRender((prev) => !prev);
        })
        .catch((err) => notification?.error({ message: err?.message || err }))
        .finally(() => {
          setUpdating(false);
          refetchSliderFn();
        });
    }
  };

  const handleOnSubmitSearchBanner = async (value) => {
    const name = "banner_search";
    const title = value?.title || "";
    const description = value?.description || "";
    const url = value?.[name]?.file?.response?.url;

    await createBanner({ url, name, title, description })
      .then(() => {
        notification?.success({ message: "Thành công!" });

        setForceRender((prev) => !prev);
      })
      .catch((err) => notification?.error({ message: err?.message || err }))
      .finally(() => {
        setUpdating(false);
        refetchBannerFn();
      });
  };

  const bannerTopColor = useMemo(() => {
    const bannerTop = find(bannerList, (item) => item?.name === "banner_top");
    return bannerTop?.color;
  }, [bannerList]);

  return (
    <Fragment>
      <Space direction='vertical' className='p-3'>
        <Breadcrumb
          items={[
            {
              href: "/dashboard",
              title: <HomeOutlined />,
            },
            {
              href: "/dashboard/order-manager",
              title: <span>Hình ảnh</span>,
            },
            {
              title: <span>Tất cả hình ảnh</span>,
            },
          ]}
        />

        <Space direction='vertical'>
          <Typography.Title level={4} className='my-4'>
            Hình ảnh
          </Typography.Title>
          <div className='flex gap-5 p-5 bg-white'>
            <div className='border rounded-lg p-5 flex-[0.5]'>
              <Row gutter={24}>
                <Col span={24}>
                  <Typography.Title level={1} className='mb-1 text-center'>
                    Banners
                  </Typography.Title>
                </Col>
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        TOP BANNER (1240 x 50px)
                      </Tag>
                      <Image src={find(banners, (item) => item?.name === "banner_top")?.url || "https://pic.pnnet.dev/1240x50"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='banner_top' className='mb-3'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                      <Form.Item name='color' className='mb-3'>
                        <Input value={color} hidden></Input>
                        <ColorPicker
                          defaultValue={bannerTopColor}
                          onChange={(color) => setColor(color.toHexString())}
                          showText={(color) => <Typography>Màu nền ({color.toHexString()})</Typography>}
                          arrow
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form
                  onFinish={handleOnSubmitSearchBanner}
                  className='inline-block w-full'
                  spellCheck={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={24}
                  form={form}
                >
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        SEARCH BANNER (600 x 150px)
                      </Tag>
                      <Image src={find(banners, (item) => item?.name === "banner_search")?.url || "https://pic.pnnet.dev/600x150"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='title' label='Tiêu đề'>
                        <Input placeholder='Nhập câu khẩu hiệu' />
                      </Form.Item>
                      <Form.Item name='description' label='Mô tả'>
                        <Input.TextArea
                          placeholder='Nhập mô tả ngắn'
                          count={{
                            max: 128,
                            show: true,
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='banner_search'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        BANNER 1 (1490 x 570px)
                      </Tag>
                      <Image src={find(banners, (item) => item?.name === "banner1")?.url || "https://pic.pnnet.dev/1490x570"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Form.Item name='banner1'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        BANNER 2 (1490 x 570px)
                      </Tag>
                      <Image src={find(banners, (item) => item?.name === "banner2")?.url || "https://pic.pnnet.dev/1490x570"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Form.Item name='banner2'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        BANNER 3 (1245 x 600px)
                      </Tag>
                      <Image src={find(banners, (item) => item?.name === "banner3")?.url || "https://pic.pnnet.dev/1245x600"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Form.Item name='banner3'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit} className='inline-block w-full'>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        BANNER 4 (600 x 600px)
                      </Tag>
                      <Image src={find(banners, (item) => item?.name === "banner4")?.url || "https://pic.pnnet.dev/600x600"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='banner4'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
              </Row>
            </div>
            <div className='border rounded-lg p-5 flex-[0.5]'>
              <Row gutter={24}>
                <Col span={24}>
                  <Typography.Title level={1} className='mb-1 text-center'>
                    Sliders
                  </Typography.Title>
                </Col>
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        SLIDER 1 (1590 x 560px)
                      </Tag>
                      <Image src={find(sliders, (item) => item?.name === "slider1")?.url || "https://pic.pnnet.dev/1590x560"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='slider1'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        SLIDER 2 (1590 x 560px)
                      </Tag>
                      <Image src={find(sliders, (item) => item?.name === "slider2")?.url || "https://pic.pnnet.dev/1590x560"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='slider2'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        SLIDER 3 (1590 x 560px)
                      </Tag>
                      <Image src={find(sliders, (item) => item?.name === "slider3")?.url || "https://pic.pnnet.dev/1590x560"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='slider3'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit}>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        SLIDER 4 (1590 x 560px)
                      </Tag>
                      <Image src={find(sliders, (item) => item?.name === "slider4")?.url || "https://pic.pnnet.dev/1590x560"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='slider4'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
                <Form onFinish={handleOnSubmit} className='inline-block w-full'>
                  <Col span={24}>
                    <Col span={24} align='center' className='mb-3 flex flex-col text-center'>
                      <Tag color='green-inverse' className='mb-3 text-center'>
                        SLIDER 5 (1590 x 560px)
                      </Tag>
                      <Image src={find(sliders, (item) => item?.name === "slider5")?.url || "https://pic.pnnet.dev/1590x560"} alt='' loading='lazy' />
                    </Col>
                    <Col span={24} align='center' className='mb-3'>
                      <Typography className='mb-1'></Typography>
                      <Form.Item name='slider5'>
                        <Upload action={process.env.REACT_APP_BACKEND_URL + "media/upload"} listType='picture'>
                          <Button icon={<UploadOutlined />}>Tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24} align='right' className='mt-8'>
                      <Button type='primary' htmlType='submit' loading={updating}>
                        Cập nhật
                      </Button>
                    </Col>
                  </Col>
                </Form>
                <Divider />
              </Row>
            </div>
          </div>
        </Space>
      </Space>
    </Fragment>
  );
};

export default FileManagerDashboard;
