import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { HomeOutlined, LockOutlined, MailOutlined, MobileOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography, notification } from "antd";
import { createUser } from "../../services/userServices";
import Link from "antd/es/typography/Link";
import { useNavigate } from "react-router-dom";
import { setShowLoginModal, setShowRegisterModal } from "../../redux/reducer/modalReducer";
import { useDispatch } from "react-redux";
import { getDistrict, getProvince, getWard } from "../../services/provinceServices";
import { filter, map } from "lodash";

const Register = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);

  const { data: provinceList, isFetching: fetchingProvince } = useQuery({ queryKey: "getProvince", queryFn: getProvince, refetchOnWindowFocus: false });

  const { data: districtList, isFetching: fetchingDistrict } = useQuery({ queryKey: "getDistrict", queryFn: getDistrict, refetchOnWindowFocus: false });

  const { data: wardList, isFetching: fetchingWard } = useQuery({ queryKey: "getWard", queryFn: getWard, refetchOnWindowFocus: false });

  const [address, setAddress] = useState({ provinces: [], districts: [], wards: [] });

  const mutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      dispatch(setShowRegisterModal(false));
      notification.success({ description: "Đăng ký tài khoản thành công!", placement: "top" });
      navigate("/login");
      dispatch(setShowLoginModal(true));
    },
    onError: (error) => notification.error({ description: error?.response?.data?.message, placement: "top" }),
  });

  const handleOnSubmit = (data) => mutation.mutate(data);

  const provinces = useMemo(() => {
    if (provinceList && provinceList.length > 0) {
      const provinces = map(provinceList, (item) => ({ label: item?.name, value: item?.idProvince }));
      return provinces;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceList, fetchingProvince]);

  const districts = useMemo(() => {
    if (districtList && districtList.length > 0) {
      const districts = map(districtList, (item) => ({ idProvince: item?.idProvince, label: item?.name, value: item?.idDistrict }));
      return districts;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtList, fetchingDistrict]);

  // eslint-disable-next-line no-unused-vars
  const wards = useMemo(() => {
    if (wardList && wardList.length > 0) {
      const wards = map(wardList, (item) => ({ idDistrict: item?.idDistrict, label: item?.name, value: item?.idWard }));
      setAddress({ provinces, districts, wards });
      return wards;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardList, fetchingWard]);

  return (
      <Modal open centered closable className='max-w-[28rem]' footer={null} onCancel={() => dispatch(setShowRegisterModal(false))} destroyOnClose={true}>
        <Space direction='vertical' className='min-w-full mt-6'>
          <Typography.Title level={5} className='text-center flex justify-center'>
            <img src='/logo.png' width={150} alt='' className='bg-primary p-3 rounded-full' />
          </Typography.Title>
          <Form onFinish={handleOnSubmit} spellCheck={false} wrapperCol={24} labelCol={24}>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name='username'
                  label='Tên đăng nhập'
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên đăng nhập!",
                    },
                  ]}
                >
                  <Input placeholder='Tên đăng nhập' allowClear={true} prefix={<UserOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label='Mật khẩu'
                  labelCol={{ span: 24 }}
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu!",
                    },
                  ]}
                >
                  <Input.Password placeholder='Mật khẩu' prefix={<LockOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  allowClear={true}
                  name='email'
                  label='Email'
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập email!",
                    },
                  ]}
                >
                  <Input type='email' placeholder='Email' allowClear={true} prefix={<MailOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  allowClear={true}
                  name='phone'
                  label='Số điện thoại'
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại!",
                    },
                  ]}
                >
                  <Input type='tel' placeholder='Số điện thoại' allowClear={true} prefix={<MobileOutlined className='mr-2 text-gray-500' /> } />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Địa chỉ' labelCol={{ span: 24 }} name='road'>
                  <Input placeholder='Nhập số nhà tên đường' allowClear={true} prefix={<HomeOutlined className='mr-2 text-gray-500' />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Tỉnh thành phố' labelCol={{ span: 24 }} name='province'>
                  <Select
                    options={provinces}
                    placeholder='Chọn tỉnh thành phố'
                    allowClear={true}
                    prefix={<HomeOutlined className='mr-2 text-gray-500' />}
                    virtual={false}
                    filterOption='label'
                    onSelect={(val) => {
                      const filteredDistrict = filter(address.districts, (item) => item?.idProvince === val);
                      setAddress((prev) => ({ ...prev, districts: filteredDistrict }));
                      setDisabled(false);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Quận huyện' labelCol={{ span: 24 }} name='district'>
                  <Select
                    options={address?.districts}
                    placeholder='Chọn quận huyện'
                    allowClear={true}
                    prefix={<HomeOutlined className='mr-2 text-gray-500' />}
                    virtual={false}
                    onSelect={(val) => {
                      const filteredWard = filter(address.wards, (item) => item?.idDistrict === val);
                      setAddress((prev) => ({ ...prev, wards: filteredWard }));
                    }}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Phường xã' labelCol={{ span: 24 }} name='ward'>
                  <Select
                    options={address?.wards}
                    placeholder='Chọn phường xã'
                    allowClear={true}
                    prefix={<HomeOutlined className='mr-2 text-gray-500' />}
                    virtual={false}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Space className='flex items-start'>
              <Form.Item name='agreePrivacyPolicy' valuePropName='checked'>
                <Checkbox></Checkbox>
              </Form.Item>
              <Typography.Text>
                Tôi đã đọc và đồng ý với
                <Typography.Link className='mx-1 text-justify'>Điều kiện giao dịch chung</Typography.Link>
                và
                <Typography.Link className='mx-1 text-justify'>Chính sách bảo mật thông tin</Typography.Link>
                của Heathymall
              </Typography.Text>
            </Space>

            <Space className='flex items-center '>
              <Form.Item name='getDiscountInfo' valuePropName='checked' className='m-0'>
                <Checkbox></Checkbox>
              </Form.Item>
              <Typography.Text className='m-0'>Nhận thông tin khuyến mãi</Typography.Text>
            </Space>

            <Row>
              <Button type='primary' htmlType='submit' className='mx-auto min-w-full min-h-9 mt-5'>
                Đăng ký
              </Button>
            </Row>
          </Form>
          <Typography.Text className='text-xs'>
            Đã có tài khoản?
            <Link
              onClick={() => {
                dispatch(setShowRegisterModal(false));
                dispatch(setShowLoginModal(true));
              }}
              className='text-xs ml-1'
            >
              Đăng nhập
            </Link>
          </Typography.Text>
        </Space>
      </Modal>
  );
};

export default Register;
