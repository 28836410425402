import { Col, Pagination, Row, Spin, Typography, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Post from "../components/Post";
import { find, map } from "lodash";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getAllSubject } from "../services/subjectServices";
import { getAllNews } from "../services/newsServices";

const SubjectPage = () => {
  const { subject: subjectSlug } = useParams();

  const [pagination, setPagination] = useState({ page: 1, size: 6 });

  const {
    data: news,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: "getAllNews",
    queryFn: async () => {
      const data = await getAllNews({ params: { subject: subjectSlug, ...pagination } })
        .then((res) => res)
        .catch((err) =>
          notification.error({
            message: err?.message || err?.data?.message || "Có lỗi xảy ra!",
          })
        );
      return data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: subjects } = useQuery({ queryKey: "getAllSubject", queryFn: getAllSubject, enabled: true, refetchOnWindowFocus: false });

  const posts = useMemo(() => {
    if (news?.data && news?.data?.length >= 0) {
      const formattedNews = map(news?.data ?? [], (item) => ({
        ...item,
        subject: find(subjects, (elm) => elm?.slug === item?.subject)?.name,
      }));
      return formattedNews;
    }
  }, [news, subjects]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <div className='wide mt-8'>
        <Row gutter={24}>
          <Col span={18} className='flex flex-col items-center'>
            <div className='flex flex-col gap-3'>
              {map(posts, (item, index) => (
                <Post key={index} posts={[item]} subject={item} isSubjectPage={true} />
              ))}
            </div>
            <Pagination
              className='mt-auto pt-8'
              total={news?.total ?? 0}
              onChange={(page, size) => setPagination({ page, size })}
              showTotal={(total) => `Tổng ${total} bài viết`}
              showTitle
              pageSize={pagination?.size}
            />
          </Col>
          <Col span={6} className='bg-white shadow-sm flex flex-col gap-3 p-3'>
            <Typography.Title level={4} className='m-3'>
              Chuyên mục nổi bật
            </Typography.Title>
            {map(subjects, (subject) => (
              <a href={`/chuyen-muc/${subject?.slug}`}>
                <div className='flex items-center gap-3 p-2 rounded-lg' style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
                  <div
                    className='!w-[64px] !h-[64px]'
                    style={{
                      backgroundImage: `url(${subject?.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  />
                  <div className='flex-[0.9]'>
                    <Typography className='line-clamp-1 font-semibold'>{subject?.name}</Typography>
                    <Typography className='text-sm font-light line-clamp-1'>{subject?.description}</Typography>
                  </div>
                </div>
              </a>
            ))}
          </Col>
        </Row>
      </div>
      <Spin fullscreen spinning={isFetching} tip='Đang tải dữ liệu...' />
    </>
  );
};

export default SubjectPage;
