import axios from "axios";
import instance from "./config";

const instanceNoAuth = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
instanceNoAuth.interceptors.response.use((response) => response?.data);

export const createCategory = async (params) => instance.post("/category/create-category", params);

export const getAllCategory = async (params) => instanceNoAuth.get("/category/get-all-category", params);

export const updateCategory = async (params) => instance.put("/category/update-category", params);

export const deleteCategory = async (params) => instance.delete("/category/delete-category", { params });
