import short from "short-uuid";
import moment from "moment";
import { filter, find, reduce } from "lodash";

export const roles = [
  {
    label: "Quản lý",
    value: "admin",
  },
  {
    label: "Nhân viên",
    value: "employee",
  },
  {
    label: "Người dùng",
    value: "user",
  },
];

export const getRole = (val) => {
  if (val) return roles.find((i) => i.value === val);
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatterNumber = (val) => {
  if (!val) return null;
  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val) => {
  if (!val) return null;
  // eslint-disable-next-line no-useless-escape
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(2);
};

export const formatNumberToString = (val) => {
  if (val >= 1000) {
    return `${(val / 1000).toFixed(1)}k`;
  } else {
    return val;
  }
};

export const getDosageList = (value) => {
  const dosageList = [
    {
      value: 1,
      label: "Bột",
    },
    {
      value: 2,
      label: "Chất lỏng",
    },
    {
      value: 3,
      label: "Viên nang",
    },
    {
      value: 4,
      label: "Viên nén",
    },
    {
      value: 5,
      label: "Viên nhai",
    },
    {
      value: 10,
      label: "Khác",
    },
  ];
  if (!value) return dosageList;
  return find(dosageList, (item) => item?.value === value);
};

export const getPackingList = (value) => {
  const packingList = [
    {
      value: 1,
      label: "Chai",
    },
    {
      value: 2,
      label: "Lọ",
    },
    {
      value: 3,
      label: "Túi",
    },
    {
      value: 4,
      label: "Hộp",
    },
    {
      value: 5,
      label: "Gói",
    },
    {
      value: 6,
      label: "Chiếc",
    },
    {
      value: 7,
      label: "Cái",
    },
    {
      value: 10,
      label: "Khác",
    },
  ];
  if (!value) return packingList;
  return find(packingList, (item) => item?.value === value);
};

export const paymentMethod = [
  {
    label: "Tiền mặt",
    image: "/cod.png",
    value: "cash",
  },
  // {
  //   label: "VNPay",
  //   image: "/",
  //   value: "vnpay",
  // },
  {
    label: "Ví Momo",
    image: "/momo.png",
    value: "momo",
  },
  {
    label: "Ví ZaloPay",
    image: "/zalo.png",
    value: "zalopay",
  },
  {
    label: "Thẻ tín dụng",
    image: "/visa.png",
    value: "visa",
  },
  {
    label: "Thẻ ATM",
    image: "/atm.png",
    value: "atm",
  },
];

export const getDiscountPercent = (original, discount) => {
  const percent = parseInt(((Number(original) - Number(discount)) * 100) / Number(original));
  return percent;
};

export const getDiscountPrice = (original, discountPercent) => {
  const discountPrice = parseInt(
    original - (Number(original) * Number(discountPercent)) / 100 >= 0 ? original - (Number(original) * Number(discountPercent)) / 100 : 0
  );
  return discountPrice;
};

export const generateDocumentNumber = () => {
  const documentNumber = "DH" + moment(Date.now()).format("DDMMYY").toLowerCase() + "-" + short.generate();
  return documentNumber;
};

export const getOrderStatus = (value) => {
  const orderStatus = [
    {
      label: "Đang chuẩn bị",
      value: 1,
      color: "orange",
    },
    {
      label: "Đang đóng gói",
      value: 2,
      color: "yellow",
    },
    {
      label: "Đang vận chuyển",
      value: 3,
      color: "blue",
    },
    {
      label: "Giao thành công",
      value: 4,
      color: "green",
    },
    {
      label: "Giao thất bại",
      value: 5,
      color: "red",
    },
  ];
  if (value) {
    return find(orderStatus, (item) => item?.value === value);
  } else {
    return orderStatus;
  }
};

export const getRandomRGBAColors = (count) => {
  const colors = new Set();
  while (colors.size < count) {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = 0.5;
    colors.add(`rgba(${r},${g},${b},${a.toFixed(2)})`);
  }
  return Array.from(colors);
};

export const getSaleOrderDetails = (saleOrders = []) => {
  const result = [];
  for (let month = 0; month <= 11; month++) {
    result.push({
      label: `Tháng ${month + 1}`,
      amount: reduce(
        filter(saleOrders, (item) => moment(item?.createAt).month() === month && moment(item?.createAt).year() === new Date().getFullYear()),
        (acc, cur) => (acc += cur?.paymentAmount),
        0
      ),
      quantity: reduce(
        filter(saleOrders, (item) => moment(item?.createAt).month() === month && moment(item?.createAt).year() === new Date().getFullYear()),
        (acc, cur) => (acc += cur?.totalQuantity),
        0
      ),
      orders: filter(saleOrders, (item) => moment(item?.createAt).month() === month && moment(item?.createAt).year() === new Date().getFullYear())?.length ?? 0,
    });
  }
  return result;
};
