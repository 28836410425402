import React from "react";
import { Link } from "react-router-dom";

const Logo = (props) => {
  return (
    <Link
      to={"/"}
      className='border-[2px] border-white rounded-full mt-2 p-3 hidden lg:block'
      style={{
        // boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
      }}
    >
      <img alt='https://heathymall.vn' className='bg-primary rounded-full' {...props} />
    </Link>
  );
};

export default Logo;
