/* eslint-disable react-hooks/exhaustive-deps */
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Divider, Space, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useMemo } from "react";

import { useQuery } from "react-query";
import { getAllProduct } from "../../services/productServices";
import { getAllCategory } from "../../services/categoryServices";
import { getAllBrand } from "../../services/brandServices";
import { getAllUser } from "../../services/userServices";
import { getSaleOrder } from "../../services/saleOrderService";
import { filter, map, reduce } from "lodash";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, LineElement, PointElement } from "chart.js";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { getRandomRGBAColors } from "../../utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { getSaleOrderDetails } from "../../utils";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement);

const MainDashboard = () => {
  useEffect(() => {
    document.title = "Trang quản lý";
  }, []);

  const { currentUser } = useSelector((state) => state?.authReducer?.login);

  const { data: productList, isFetching: fetchingProduct } = useQuery({
    queryKey: "getAllProduct",
    queryFn: getAllProduct,
    enabled: true,
    refetchOnWindowFocus: false,
  });
  const { data: categoryList, isFetching: fetchingCategory } = useQuery({
    queryKey: "getAllCategory",
    queryFn: getAllCategory,
    enabled: true,
    refetchOnWindowFocus: false,
  });
  const { data: brandList, isFetching: fetchingBrand } = useQuery({
    queryKey: "getAllBrand",
    queryFn: getAllBrand,
    enabled: true,
    refetchOnWindowFocus: false,
  });
  const { data: userList, isFetching: fetchingUser } = useQuery({ queryKey: "getAllUser", queryFn: getAllUser, enabled: true, refetchOnWindowFocus: false });
  const { data: saleOrderList, isFetching: fetchingSaleOrder } = useQuery({
    queryKey: "getAllSaleOrder",
    queryFn: getSaleOrder,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const products = useMemo(() => {
    if (!fetchingProduct && productList) return productList.data;
  }, [productList, fetchingProduct]);

  const categories = useMemo(() => {
    if (!fetchingCategory && categoryList) return categoryList;
  }, [categoryList, fetchingCategory]);

  const brands = useMemo(() => {
    if (!fetchingBrand && brandList) return brandList;
  }, [brandList, fetchingBrand]);

  const users = useMemo(() => {
    if (!fetchingUser && userList) return userList;
  }, [userList, fetchingUser]);

  const saleOrders = useMemo(() => {
    if (!fetchingSaleOrder && saleOrderList) return saleOrderList;
  }, [saleOrderList, fetchingSaleOrder]);

  const filterWithBrand = useMemo(
    () =>
      map(brands, (item) => ({
        brand: item?.name,
        stock: reduce(
          filter(products, (p) => p?.brand === item?.name),
          (acc, cur) => (acc += cur?.stock),
          0
        ),
        sold: reduce(
          filter(products, (p) => p?.brand === item?.name),
          (acc, cur) => (acc += cur?.sold),
          0
        ),
      })),
    [brands, products]
  );

  const filterWithCategory = useMemo(
    () =>
      map(categories, (item) => ({
        category: item?.name,
        stock: reduce(
          filter(products, (p) => p?.category === item?.slug),
          (acc, cur) => (acc += cur?.stock),
          0
        ),
        sold: reduce(
          filter(products, (p) => p?.category === item?.slug),
          (acc, cur) => (acc += cur?.sold),
          0
        ),
      })),
    [categories, products]
  );

  const filterdWithSaleOrder = useMemo(() => getSaleOrderDetails(saleOrders), [saleOrders]);

  const filterOverview = useMemo(
    () => [
      {
        label: "Sản phẩm",
        value: products?.length,
      },
      {
        label: "Đơn hàng",
        value: saleOrders?.length,
      },
      {
        label: "Người dùng",
        value: users?.length,
      },
      {
        label: "Danh mục",
        value: categories?.length,
      },
      {
        label: "Thương hiệu",
        value: brands?.length,
      },
    ],
    [products, categories, brands, users, saleOrders]
  );

  const options1 = useMemo(
    () => ({
      data: {
        labels: map(filterOverview, (item) => item?.label),
        datasets: [
          {
            label: "Số lượng",
            data: map(filterOverview, (item) => item?.value),
            backgroundColor: getRandomRGBAColors(5),
          },
        ],
      },
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Báo cáo tổng quát",
        },
      },
    }),
    [filterOverview]
  );

  const options2 = useMemo(
    () => ({
      data: {
        labels: map(filterWithBrand, (item) => item?.brand),
        datasets: [
          {
            label: "Tồn kho",
            data: map(filterWithBrand, (item) => item?.stock),
            backgroundColor: getRandomRGBAColors(filterWithBrand?.length),
          },
          {
            label: "Đã bán",
            data: map(filterWithBrand, (item) => item?.sold),
            backgroundColor: getRandomRGBAColors(filterWithBrand?.length),
          },
        ],
      },
      responsive: true,
      color: "gray",
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Báo cáo theo thương hiệu",
        },
      },
    }),
    [filterWithBrand]
  );

  const options3 = useMemo(
    () => ({
      data: {
        labels: map(filterWithCategory, (item) => item?.category),
        datasets: [
          {
            label: "Đã bán",
            data: map(filterWithCategory, (item) => item?.sold),
            backgroundColor: getRandomRGBAColors(filterWithCategory.length),
          },
          {
            label: "Tồn kho",
            data: map(filterWithCategory, (item) => item?.stock),
            backgroundColor: getRandomRGBAColors(filterWithCategory.length),
          },
        ],
      },
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Báo cáo theo danh mục",
        },
      },
    }),
    [filterWithCategory]
  );

  const options4 = useMemo(
    () => ({
      data: {
        labels: map(filterdWithSaleOrder, (item) => item?.label),
        datasets: [
          {
            label: "Doanh thu",
            data: map(filterdWithSaleOrder, (item) => item?.amount),
            backgroundColor: getRandomRGBAColors(1),
          },
        ],
      },
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Báo cáo theo doanh thu",
        },
      },
    }),
    [filterdWithSaleOrder]
  );

  const options5 = useMemo(
    () => ({
      data: {
        labels: map(filterdWithSaleOrder, (item) => item?.label),
        datasets: [
          {
            label: "Số lượng đơn hàng",
            data: map(filterdWithSaleOrder, (item) => item?.orders),
            backgroundColor: getRandomRGBAColors(1),
          },
          {
            label: "Sản phẩm đã bán",
            data: map(filterdWithSaleOrder, (item) => item?.quantity),
            backgroundColor: getRandomRGBAColors(1),
          },
        ],
      },
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Báo cáo theo số lượng",
        },
      },
    }),
    [filterdWithSaleOrder]
  );

  const options6 = useMemo(
    () => ({
      data: {
        labels: map(filterWithCategory, (item) => item?.category),
        datasets: [
          {
            label: "Đã bán",
            data: map(filterWithCategory, (item) => item?.sold),
            backgroundColor: getRandomRGBAColors(1),
          },
          {
            label: "Tồn kho",
            data: map(filterWithCategory, (item) => item?.stock),
            backgroundColor: getRandomRGBAColors(1),
          },
        ],
      },
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Báo cáo theo danh mục",
        },
      },
    }),
    [filterWithCategory]
  );

  return (
    <Space direction='vertical' className='p-3'>
      <Breadcrumb
        items={[
          {
            href: "/dashboard",
            title: <HomeOutlined />,
          },
          {
            href: "/dashboard",
            title: <span>Tổng quan</span>,
          },
          {
            title: <span>Báo cáo chi tiết</span>,
          },
        ]}
      />
      <Space direction='vertical'>
        <Typography.Title level={4} className='my-4'>
          Tổng quan
        </Typography.Title>
      </Space>
      <Space className='ml-1' direction='horizontal' size={4}>
        <Avatar src={currentUser?.avatar} icon={<UserOutlined />} />
        <Divider type='vertical' className='mx-1' dashed />
        <Typography>Xin chào, </Typography>
        <Typography className='font-semibold'>{currentUser?.fullname || currentUser?.username || "Người dùng"}</Typography>
        <Typography>!</Typography>
        <Divider type='vertical' dashed />
        <Typography className='text-sm'>{moment(new Date()).format("DD/MM/YYYY")}</Typography>
      </Space>
      <Content className='bg-white rounded-lg flex items-start justify-between p-6 w-full'>
        <div className='w-[65%]'>
          <Bar className='w-full' data={options1?.data} options={options1} />
        </div>
        <div className='w-[30%]'>
          <Doughnut className='w-full' data={options2?.data} options={options2} />
        </div>
      </Content>
      <Content className='bg-white rounded-lg flex items-start justify-between gap-6 p-6 w-full'>
        <div className='w-[50%]'>
          <Line className='w-full' data={options4?.data} options={options4} />
        </div>
        <div className='w-[50%]'>
          <Bar className='w-full' data={options5?.data} options={options5} />
        </div>
      </Content>
      <Content className='bg-white rounded-lg flex items-start justify-between gap-6 p-6 w-full'>
        <div className='w-[65%]'>
          <Bar className='w-full' data={options6?.data} options={options6} />
        </div>
        <div className='w-[30%]'>
          <Pie className='w-full' data={options3?.data} options={options3} />
        </div>
      </Content>
    </Space>
  );
};

export default MainDashboard;
