import React, { Fragment, useCallback, useMemo, useState } from "react";

import Register from "../Register";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Divider, Dropdown, FloatButton, Image, Input, Space, Spin, Tag, Tooltip, Typography, notification } from "antd";
import {
  AppstoreOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  CommentOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { find, isEmpty, map, reduce } from "lodash";
import { Link, useNavigate } from "react-router-dom";

import MenuDropdown from "./MenuDropdown";
import Logo from "./Logo";
import Cart from "./Cart";
import Hotline from "./Hotline";
import Login from "../Login";
import { setShowLoginModal } from "../../redux/reducer/modalReducer";
import TopBanner from "../../components/TopBanner";
import { findProduct } from "../../services/productServices";
import { NumericFormat } from "react-number-format";
import * as userServices from "../../services/userServices";
import { removeAllRecentKeyword, setRecentKeyword } from "../../redux/reducer/recentKeywordReducer";
import { useQuery } from "react-query";
import { getAllCategory } from "../../services/categoryServices";

const Header = () => {
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state?.cartReducer);

  const { banners } = useSelector((state) => state?.imageReducer);

  const { recentKeywords } = useSelector((state) => state?.recentKeywordReducer);

  // const { categories } = useSelector((state) => state?.categoryReducer);

  const [keyword, setKeyword] = useState("");

  const [products, setProducts] = useState([]);

  const [searching, setSearching] = useState(false);

  const { isShowRegisterModal, isShowLoginModal } = useSelector((state) => state?.modalReducer);

  const { currentUser } = useSelector((state) => state.authReducer?.login);

  const dispatch = useDispatch();

  const { data: categories } = useQuery({ queryKey: "getAllCategory", queryFn: getAllCategory, enabled: true, refetchOnWindowFocus: false });

  const handleOnSearch = useCallback(async (value) => {
    setSearching(true);
    await findProduct({ params: { keyword: value, limits: 5 } })
      .then((res) => setProducts(res))
      .catch((err) => notification.error({ message: "Có lỗi xảy ra, vui lòng nhập lại!" }))
      .finally(() => setSearching(false));
  }, []);

  const handleLogout = async () => {
    await userServices
      .logoutUser(null, dispatch)
      .then((res) => notification.success({ description: res?.response?.data?.message || "Đăng xuất thành công!", placement: "top" }))
      .finally(() => navigate("/"));
  };

  const cartCounterElement = useMemo(
    () => (
      <div className='relative mt-[2px]'>
        <Badge
          count={reduce(
            cart,
            (acc, cur) => {
              return (acc += cur?.quantity);
            },
            0
          )}
        >
          <ShoppingOutlined className='text-[22px] text-white cursor-pointer hover:opacity-80' />
        </Badge>
      </div>
    ),
    [cart]
  );

  const categoryElement = useMemo(
    () => (
      <Tag color='white' className='px-3 py-[2px] rounded-sm group hidden lg:block'>
        <Space className='cursor-pointer text-primary flex items-center rounded-lg'>
          <i className='bi bi-list text-primary'></i>
          <Typography className='text-primary font-semibold'>DANH MỤC</Typography>
          <CaretDownOutlined />
        </Space>
      </Tag>
    ),
    []
  );

  const userElementDropdown = useMemo(
    () => (
      <div className='bg-white rounded-lg p-3 shadow-lg flex flex-col gap-3'>
        <Link to='/account' className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'>
          <i className='bi bi-person-lines-fill'></i>
          <Typography className='text-inherit'>Thông tin cá nhân</Typography>
        </Link>
        {(currentUser?.role === "admin" || currentUser?.role === "employee") && (
          <Link
            to='/dashboard'
            target='_blank'
            className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'
          >
            <i className='bi bi-speedometer'></i>
            <Typography className='text-inherit'>Trang quản lý</Typography>
          </Link>
        )}
        <Link to='/orders' className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'>
          <i className='bi bi-box-seam-fill'></i>
          <Typography className='text-inherit'>Lịch sử đơn hàng</Typography>
        </Link>
        <div onClick={() => handleLogout()} className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'>
          <i className='bi bi-box-arrow-right'></i>
          <Typography className='text-inherit'>Đăng xuất</Typography>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const userElement = useMemo(
    () => (
      <div className='flex bg-white items-center rounded-full hover:bg-opacity-90 cursor-pointer py-1'>
        {currentUser ? (
          <div className='flex items-center px-2'>
            <Avatar src={currentUser?.avatar} className='mr-2' icon={<UserOutlined />} shape='circle'>
              {currentUser?.fullname || currentUser?.username}
            </Avatar>
            <Typography className='text-primary font-semibold text-xs'>
              {currentUser?.fullname || currentUser?.username}
              <CaretDownFilled className='ml-2' />
            </Typography>
          </div>
        ) : (
          <div className='flex items-center py-2 px-3' onClick={() => dispatch(setShowLoginModal(true))}>
            <UserOutlined className='text-primary mb-[2px] mr-[6px] text-[16px]' />
            <Typography className='text-primary  font-semibold text-xs'>Đăng nhập / Đăng ký</Typography>
          </div>
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const searchElement = useMemo(
    () => (
      <div className='flex w-full flex-col gap-2'>
        <Input
          spellCheck={false}
          onChange={(e) => handleOnSearch(e?.target?.value)}
          onBlur={(e) => setKeyword(e?.target?.value)}
          className='rounded-full py-1 pl-4 pr-2 border-0 outline-0 lg:py-2 lg:pl-6 lg:border-0 lg:outline-0'
          placeholder='Nhập tên sản phẩm, thương hiệu cần tìm kiếm...'
          suffix={
            <div className='bg-primary opacity-75 hover:opacity-100 w-[32px] h-[32px] rounded-full flex items-center justify-center'>
              <SearchOutlined className='text-[18px] text-white cursor-pointer' />
            </div>
          }
        />
        <ul className='gap-3 px-5 text-sm !text-white hidden lg:flex'>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/sắt' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              sắt
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/perdays' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              perdays
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/axit folic' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              axit folic
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/mẹ bầu' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              mẹ bầu
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/vitamin tổng hợp' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              vitamin tổng hợp
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/lifespace' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              lifespace
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/cải thiện thị lực' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              cải thiện thị lực
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/men vi sinh' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              men vi sinh
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/mờ thâm' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              mờ thâm
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/tiêu hóa' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              tiêu hóa
            </a>
          </li>
          {/* {map(recentKeywords, (keyword) => (
            <li className='line-clamp-1 text-ellipsis'>
              <a href={`/tim-kiem/${keyword}`} className='text-white text-[10px]' onClick={() => dispatch(setRecentKeyword(keyword))}>
                {keyword}
              </a>
            </li>
          ))} */}
        </ul>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recentKeywords]
  );

  const searchDropdown = useMemo(
    () => (
      <div className='bg-white w-[98%] left-[50%] translate-x-[-50%] lg:top-[-25px] !absolute rounded-xl shadow-2xl p-4 z-[9999] flex flex-col'>
        <div className='min-h-[150px]'>
          <Space className='flex items-center justify-between'>
            <Space direction='vertical' size={2}>
              <Typography.Title level={5} className='!mb-0 line-clamp-1 lg:line-clamp-2'>
                {find(banners, (item) => item?.name === "banner_search")?.title || "Healthymall"}
              </Typography.Title>
              <Typography className='my-0  line-clamp-1 lg:line-clamp-2'>
                {find(banners, (item) => item?.name === "banner_search")?.description || "Thực phẩm chức năng chính hãng từ Úc"}
              </Typography>
            </Space>
            <Image preview={false} src={find(banners, (item) => item?.name === "banner_search")?.url || "/perdays-logo-dark.svg"} width={120}></Image>
          </Space>
          <Divider className='my-4' />
          {isEmpty(recentKeywords) ? (
            <></>
          ) : (
            <>
              <div className='flex justify-between items-center'>
                <Typography.Title level={5}>Tìm kiếm gần đây</Typography.Title>
                <Typography className='text-primary cursor-pointer' onClick={() => dispatch(removeAllRecentKeyword([]))}>
                  Xóa lịch sử
                </Typography>
              </div>
              <Space direction='vertical' className='w-full'>
                {map(recentKeywords, (keyword) => (
                  <Link to={`/tim-kiem/${keyword}`} className='text-gray-600'>
                    <HistoryOutlined className='mr-2' />
                    {keyword}
                  </Link>
                ))}
              </Space>
              <Divider className='my-4' />
            </>
          )}
          <Typography.Title level={5}>Kết quả tìm kiếm</Typography.Title>
          <Space direction='vertical' split={<div className='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed'></div>}>
            {isEmpty(products) ? (
              <Typography className='text-gray-500'>Không tìm thấy sản phẩm</Typography>
            ) : (
              map(products, (item) => (
                <Link to={`/${item?.category}/${item?.url}`} className='flex items-center'>
                  <div className='mr-2'>
                    <Avatar src={item?.images?.[0]} shape='square' className='!w-[64px] !h-[64px] border' />
                  </div>
                  <div className='flex flex-col items-start justify-center'>
                    <div className='line-clamp-2'>{item?.name}</div>
                    <Space>
                      <Tag color='#50a199'>
                        <NumericFormat value={item?.discountPrice} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
                      </Tag>
                    </Space>
                  </div>
                </Link>
              ))
            )}
          </Space>

          <Spin
            style={{ left: "50%", position: "absolute", transform: "translateX(-50%)" }}
            spinning={searching}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
        <div className='text-center mt-5'>
          <Link to={`/tim-kiem/${keyword}`} className='text-primary font-medium px-2 py-1 rounded-md' onClick={() => dispatch(setRecentKeyword(keyword))}>
            XEM THÊM SẢN PHẨM
          </Link>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [banners, products, searching, keyword, recentKeywords]
  );

  const navbarElement = useMemo(
    () => (
      <ul className='w-full gap-5 text-sm ml-14 hidden lg:flex'>
        {map(categories ?? [], (category, index) => {
          if (index <= 7)
            return (
              <li key={index} className='list-none'>
                <a className='text-white hover:text-yellow-300 transition font-semibold' href={`/danh-muc/${category?.slug}`}>
                  {category?.name?.toUpperCase() || ""}
                </a>
              </li>
            );
        })}
        <li className='ml-auto list-none'>
          <a className='text-primary px-3 py-1 rounded-sm font-semibold bg-white text-center' href='/bai-viet'>
            <i className='bi bi-newspaper'></i> <span className='ml-1'>TIN TỨC</span>
          </a>
        </li>
      </ul>
    ),
    [categories]
  );

  return (
    <Fragment>
      <TopBanner
        src={find(banners, (item) => item?.name === "banner_top")?.url || "https://pic.pnnet.dev/1240x50"}
        color={find(banners, (item) => item?.name === "banner_top")?.color || "white"}
      />
      <div className='bg-primary pt-2 mb-4 sticky top-0 z-[50] lg:py-2 lg:mb-4 lg:sticky lg:top-0 lg:z-[50]'>
        <div className='lg:hidden md:hidden flex items-center justify-between py-0 px-3 mb-1 text-white'>
          <div className='font-bold text-[26px] p-2 flex items-center justify-center cursor-pointer'>
            <i className='bi bi-list' />
          </div>
          <Link to={"/"}>
            <img alt='logo' src='/logo.png' width={150} />
          </Link>
          <div className='font-bold text-[18px] w-[30px] h-[30px] rounded-full p-4 flex items-center justify-center cursor-pointer bg-[rgba(0,0,0,0.3)]'>
            <i className='bi bi-cart2' />
          </div>
        </div>
        <header className='flex gap-5 items-start w-full wide px-3 xl:px-0 mx-auto'>
          <div className='flex flex-1 gap-10 items-start justify-between'>
            <Logo src='/logo.png' width={200} />
            <Dropdown
              overlayStyle={{
                borderRadius: "15px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              className='relative'
              trigger={"click"}
              dropdownRender={() => searchDropdown}
            >
              {searchElement}
            </Dropdown>
          </div>
          <div className='hidden md:flex lg:flex gap-5 h-12 items-center text-white'>
            <div className='flex gap-4 items-center'>
              <Dropdown arrow dropdownRender={() => <Hotline />}>
                <QuestionCircleOutlined className='text-[22px] text-white cursor-pointer hover:opacity-80' />
              </Dropdown>
              <Dropdown arrow dropdownRender={() => <Cart />}>
                {cartCounterElement}
              </Dropdown>
            </div>
            <span className='text-gray-300'>|</span>
            <Dropdown arrow={currentUser ? true : false} dropdownRender={() => (currentUser ? userElementDropdown : <></>)}>
              {userElement}
            </Dropdown>
          </div>
        </header>
        <div className='flex wide items-center mx-auto w-full mt-4'>
          <div className='flex items-center'>
            <Dropdown
              arrow={true}
              overlayStyle={{
                borderRadius: "15px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              dropdownRender={() => <MenuDropdown />}
            >
              {categoryElement}
            </Dropdown>
          </div>
          {navbarElement}
        </div>
      </div>
      <FloatButton.Group
        trigger='hover'
        style={{
          right: 24,
        }}
        icon={<AppstoreOutlined />}
        badge={{
          count: reduce(
            cart,
            (acc, cur) => {
              return (acc += cur?.quantity);
            },
            0
          ),
          color: "red",
        }}
      >
        <Space direction='vertical' size={12}>
          <Tooltip title='Giỏ hàng' placement='left'>
            <Link to={"/gio-hang"}>
              <FloatButton
                badge={{
                  count: reduce(
                    cart,
                    (acc, cur) => {
                      return (acc += cur?.quantity);
                    },
                    0
                  ),
                  color: "red",
                }}
                icon={<ShoppingCartOutlined />}
              />
            </Link>
          </Tooltip>
          <Tooltip title='Hỗ trợ' placement='left'>
            <a href='https://zalo.me/0967711633' target='_blank' rel='noreferrer'>
              <FloatButton icon={<CommentOutlined />} />
            </a>
          </Tooltip>
        </Space>
      </FloatButton.Group>

      <FloatButton.Group style={{ right: 80 }}>
        <Tooltip title='Lên đầu'>
          <FloatButton.BackTop duration={0} icon={<i className='bi bi-chevron-up'></i>} visibilityHeight={0} />
        </Tooltip>
      </FloatButton.Group>
      {isShowLoginModal && <Login />}
      {isShowRegisterModal && <Register />}
    </Fragment>
  );
};

export default Header;
