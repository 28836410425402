import NotFound from "./components/NotFound";
import MainDashboard from "./components/Dashboard/MainDashboard";
import UsersDashboard from "./components/Dashboard/UsersDashboard";
import ProductsDashboard from "./components/Dashboard/ProductsDashboard";
import CategoryDashboard from "./components/Dashboard/CategoryDashboard";
import BrandDashboard from "./components/Dashboard/BrandDashboard";
import FileManagerDashboard from "./components/Dashboard/FileManagerDashboard";
import OrderManagerDashboard from "./components/Dashboard/OrderManagerDashboard";
import {
  CartPage,
  ProductDetailPage,
  SearchPage,
  HomePage,
  RegisterPage,
  LoginPage,
  ProfilePage,
  PaymentPage,
  OrderPage,
  NewsPage,
  PostPage,
  SubjectPage,
} from "./pages";
import FlashSaleDashboard from "./components/Dashboard/FlashSaleDashboard";
import DiscountDashboard from "./components/Dashboard/DiscountDashboard";
import CouponDashboard from "./components/Dashboard/CouponDashboard";
import SubjectDashboard from "./components/Dashboard/SubjectDashboard";
import NewsDashboard from "./components/Dashboard/NewsDashboard";

const routes = [
  {
    path: "/",
    element: HomePage,
    isDashboard: false,
  },
  {
    path: "/register",
    element: RegisterPage,
    isDashboard: false,
  },
  {
    path: "/login",
    element: LoginPage,
    isDashboard: false,
  },
  {
    path: "/dashboard",
    element: MainDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/users",
    element: UsersDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/products",
    element: ProductsDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/news",
    element: NewsDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/subjects",
    element: SubjectDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/categories",
    element: CategoryDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/brands",
    element: BrandDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/files",
    element: FileManagerDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/order-manager",
    element: OrderManagerDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/flash-sale",
    element: FlashSaleDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/discount",
    element: DiscountDashboard,
    isDashboard: true,
  },
  {
    path: "/dashboard/coupon",
    element: CouponDashboard,
    isDashboard: true,
  },
  {
    path: "*",
    element: NotFound,
    isDashboard: false,
  },
  {
    path: "/tim-kiem",
    element: SearchPage,
    isDashboard: false,
  },
  {
    path: "/danh-muc/:category",
    element: SearchPage,
    isDashboard: false,
  },
  {
    path: "/thuong-hieu/:brand",
    element: SearchPage,
    isDashboard: false,
  },
  {
    path: "/nuoc-san-xuat/:country",
    element: SearchPage,
    isDashboard: false,
  },
  {
    path: "/tim-kiem/:keyword",
    element: SearchPage,
    isDashboard: false,
  },
  {
    path: "/:category/:url",
    element: ProductDetailPage,
    isDashboard: false,
  },
  {
    path: "/gio-hang",
    element: CartPage,
    isDashboard: false,
  },
  {
    path: "/bai-viet",
    element: NewsPage,
    isDashboard: false,
  },
  {
    path: "/bai-viet/:url",
    element: PostPage,
    isDashboard: false,
  },
  {
    path: "/chuyen-muc/:subject",
    element: SubjectPage,
    isDashboard: false,
  },
  {
    path: "/checkout",
    element: PaymentPage,
    isDashboard: false,
  },
  {
    path: "/account",
    element: ProfilePage,
    isDashboard: false,
  },
  {
    path: "/orders",
    element: OrderPage,
    isDashboard: false,
  },
];

export default routes;
