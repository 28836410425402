import { createSlice } from "@reduxjs/toolkit";
import { filter, map } from "lodash";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
  },
  reducers: {
    addProduct: (state, action) => {
      const array = [...state.cart, action.payload];
      const tempObj = {};

      array.forEach((item) => {
        const productId = item?.product?._id;
        if (tempObj[productId]) {
          tempObj[productId].quantity += item?.quantity;
        } else {
          tempObj[productId] = { ...item };
        }
      });

      const mergedArray = Object.values(tempObj);

      state.cart = [...mergedArray];
    },
    removeProduct: (state, action) => {
      state.cart = filter(state.cart, (item) => item?.product?._id !== action.payload);
    },
    updateProduct: (state, action) => {
      state.cart = map(state.cart, (item) => {
        if (item?.product?._id === action.payload?.product?._id) {
          item.quantity = action.payload?.quantity;
        }
        return item;
      });
    },

    deleteProduct: (state) => {
      state.cart = [];
    },
  },
});

export const { addProduct, removeProduct, updateProduct, deleteProduct } = cartSlice.actions;

export default cartSlice.reducer;
