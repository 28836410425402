import { HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Checkbox, Col, Divider, Input, InputNumber, Layout, Row, Slider, Space, Typography } from "antd";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { formatterNumber, parserNumber } from "../utils";
import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { getAllCategory } from "../services/categoryServices";
import { find, map } from "lodash";
import { getAllBrand } from "../services/brandServices";
import ProductList from "../components/ProductList/ProductList";

const SearchPage = () => {
  const { category, brand, country, keyword } = useParams();

  const [params, setParams] = useState({ category, brand, country, keyword });

  const [inputValue, setInputValue] = useState([0, 5000000]);

  const [filterParams, setFilterParams] = useState({
    keyword: null,
    category: null,
    brand: null,
    fromCost: null,
    toCost: null,
  });

  const { data: categoryList, isFetching: fetchingCategory } = useQuery({
    queryKey: "getAllCategories",
    queryFn: getAllCategory,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: brandList, isFetching: fetchingBrand } = useQuery({
    queryKey: "getALlBrands",
    queryFn: getAllBrand,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const handleChangePriceRange = useCallback((value) => {
    setInputValue(value);
    setFilterParams((prev) => ({ ...prev, fromCost: value?.[0], toCost: value?.[1] }));
  }, []);

  const handleChangeCategory = useCallback((value) => {
    setFilterParams((prev) => ({ ...prev, category: value }));
  }, []);

  const handleChangeBrand = useCallback((value) => {
    setFilterParams((prev) => ({ ...prev, brand: value }));
  }, []);

  const handleChangeSearch = useCallback((event) => {
    setFilterParams((prev) => ({ ...prev, keyword: event?.target?.value }));
  }, []);

  const handleOnSubmit = useCallback(() => setParams(filterParams), [filterParams]);

  const categories = useMemo(() => {
    if (!fetchingCategory && categoryList && categoryList?.length > 0) {
      const categories = map(categoryList, (item) => ({ label: item?.name, value: item?.slug }));
      categories.unshift({ label: "Tất cả", value: "all" });
      return categories;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingCategory, categoryList]);

  const brands = useMemo(() => {
    if (!fetchingBrand && brandList && brandList?.length > 0) {
      const brands = map(brandList, (item) => ({ label: item?.name, value: item?.name }));
      brands.unshift({ label: "Tất cả", value: "all" });
      return brands;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingBrand, categoryList]);

  useEffect(() => {
    if (category) {
      document.title = find(categories, (item) => item?.value === category)?.label;
    }
    if (brand) {
      document.title = find(brands, (item) => item?.value === brand)?.label;
    }
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [category, brand, categories, brands]);

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            ...(category
              ? {
                  href: `/danh-muc/${find(categories, (item) => item?.value === category)?.value}`,
                  title: <span>Danh mục</span>,
                }
              : brand
              ? {
                  href: `/thuong-hieu/${find(brands, (item) => item?.value === brand)?.value}`,
                  title: <span>Thương hiệu</span>,
                }
              : country
              ? {
                  href: "/nuoc-san-xuat",
                  title: "Nước sản xuất",
                }
              : { title: "Tìm kiếm" }),
          },
          {
            ...(category
              ? {
                  title: find(categories, (item) => item?.value === category)?.label,
                }
              : brand
              ? {
                  title: find(brands, (item) => item?.value === brand)?.label,
                }
              : country
              ? {
                  title: country,
                }
              : { title: "Tất cả sản phẩm" }),
          },
        ]}
      />
      <div className='wide mx-auto relative'>
        <Layout className='min-h-screen flex items-start justify-between'>
          <Sider
            className='rounded-lg'
            width={280}
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              height: "100%",
              position: "sticky",
              backgroundColor: "white",
              left: 0,
              top: 155,
              zIndex: 1,
              padding: "16px 12px",
            }}
          >
            <Typography className='mb-2 font-semibold'>Tên sản phẩm</Typography>
            <Input
              autoComplete='true'
              onBlur={handleChangeSearch}
              spellCheck={false}
              placeholder='Tên sản phẩm, thương hiệu, mô tả'
              allowClear
              inputMode='text'
              title='Tìm kiếm'
              className='mb-2'
            />
            <Button
              className='mx-auto w-full min-h-[35px] flex items-center justify-center'
              type='primary'
              icon={<SearchOutlined />}
              title='Tìm kiếm'
              onClick={handleOnSubmit}
            >
              Tìm kiếm
            </Button>
            <Divider className='my-3' type='dashed' />
            <Typography className='mb-2 font-semibold '>Thương hiệu</Typography>
            <Checkbox.Group onChange={handleChangeBrand}>
              <Space direction='vertical'>
                {map(brands, (item) => (
                  <Checkbox value={item?.value}>{item?.label}</Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
            <Divider className='my-3' type='dashed' />
            <Typography className='mb-2 font-semibold '>Danh mục</Typography>
            <Checkbox.Group onChange={handleChangeCategory}>
              <Space direction='vertical'>
                {map(categories, (item) => (
                  <Checkbox value={item?.value}>{item?.label}</Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
            <Divider className='my-4' type='dashed' />
            <Typography className='mb-2 font-semibold'>Khoảng giá</Typography>
            <Slider
              range
              min={0}
              max={5000000}
              step={100000}
              onChange={handleChangePriceRange}
              defaultValue={inputValue}
              tooltip={{
                formatter: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
              }}
            />
            <Row gutter={20}>
              <Col span={12}>
                <InputNumber placeholder='Tối thiểu' formatter={formatterNumber} parser={parserNumber} value={inputValue?.[0]} suffix='đ' className='w-full' />
              </Col>
              <Col span={12}>
                <InputNumber placeholder='Tối đa' formatter={formatterNumber} parser={parserNumber} value={inputValue?.[1]} suffix='đ' className='w-full' />
              </Col>
              <Button className='mx-auto mt-8 w-[95%] min-h-[40px]' type='primary' onClick={handleOnSubmit}>
                Áp dụng
              </Button>
            </Row>
          </Sider>
          <Layout className='min-h-screen rounded-lg bg-secondary text-center'>
            <Space direction='vertical'>
              <ProductList params={params} page={1} size={24} placement='search' />
            </Space>
          </Layout>
        </Layout>
      </div>
    </Fragment>
  );
};

export default SearchPage;
