import React from "react";
import { Button, Result, Table, Tag, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { getOrderStatus } from "../utils";

const PaymentPage = () => {
  const { state } = useLocation();
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: () => <Typography>1</Typography>,
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "documentNumber",
      align: "center",
      key: "documentNumber",
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      align: "center",
      key: "description",
      render: (value) => <Typography className='text-left'>{value}</Typography>,
    },
    {
      title: "Tổng cộng",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "right",
      render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
    },
    {
      title: "Chiết khấu",
      dataIndex: "productDiscountAmount",
      key: "productDiscountAmount",
      align: "right",
    },
    {
      title: "Mã giảm giá",
      dataIndex: "promotionDiscount",
      key: "promotionDiscount",
      align: "right",
    },
    {
      title: "Phí ship",
      dataIndex: "shippingFee",
      key: "shippingFee",
      align: "right",
    },
    {
      title: "Thanh toán",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      align: "right",
      render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value) => <Tag color='orange'>{getOrderStatus(value)?.label}</Tag>,
    },
  ];

  return (
    <div className='wide'>
      <Result
        status='success'
        title='Đặt hàng thành công!'
        subTitle='Chúng tôi sẽ liên lạc và giao hàng sớm cho bạn, hãy lưu lại mã đơn hàng để kiểm tra nếu cần!'
        extra={[
          <Table columns={columns} dataSource={[state]} bordered />,
          <Button type='primary' key='console'>
            <Link to='/'>Trang chủ</Link>
          </Button>,
          <Button key='buy'>
            <Link to='/orders'>Xem đơn hàng</Link>
          </Button>,
        ]}
      />
    </div>
  );
};

export default PaymentPage;
