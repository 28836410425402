import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "antd/es/typography/Link";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Row, Space, Typography, notification } from "antd";

import * as userServices from "../../services/userServices";
import { setShowLoginModal, setShowRegisterModal } from "../../redux/reducer/modalReducer";
// import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { isShowLoginModal } = useSelector((state) => state?.modalReducer);

  const handleOnSubmit = async (values) => {
    const res = await userServices.loginUser(values, dispatch);
    if (!res?._id) {
      notification.error({ description: res?.response?.data?.message || "Có lỗi xảy ra!", placement: "top" });
    } else {
      dispatch(setShowLoginModal(false));
      // navigate("/");
      notification.success({ description: "Đăng nhập thành công!", placement: "top" });
    }
  };

  return (
    <>
      {isShowLoginModal && (
        <Modal
          open
          centered
          closable
          className='max-w-96'
          footer={null}
          onCancel={() => {
            dispatch(setShowLoginModal(false));
            // navigate("/");
          }}
        >
          <Space direction='vertical' className='min-w-full mt-6'>
            <Form layout='vertical' onFinish={handleOnSubmit}>
              <Typography.Title level={5} className='text-center flex justify-center'>
                <img src='/logo.png' width={150} alt='' className='bg-primary p-3 rounded-full' />
              </Typography.Title>

              <Form.Item
                className='mt-10'
                name='username'
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tài khoản!",
                  },
                ]}
              >
                <Input placeholder='Tài khoản' prefix={<UserOutlined className='mr-2 text-gray-500' />} />
              </Form.Item>

              <Form.Item
                className='mt-3'
                name='password'
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu!",
                  },
                ]}
              >
                <Input.Password placeholder='Mật khẩu' prefix={<LockOutlined className='mr-2 text-gray-500' />} />
              </Form.Item>

              <Row className='flex mt-2 h-8 justify-between items-baseline'>
                <Form.Item name='remember' valuePropName='checked'>
                  <Checkbox>Nhớ mật khẩu</Checkbox>
                </Form.Item>
                <Typography.Link
                  href='https://www.google.com/search?q=Khi+qu%C3%AAn+m%E1%BA%ADt+kh%E1%BA%A9u+ta+n%C3%AAn+t%E1%BA%A1o+t%C3%A0i+kho%E1%BA%A3n+m%E1%BB%9Bi+%3AD&oq=Khi+qu%C3%AAn+m%E1%BA%ADt+kh%E1%BA%A9u+ta+n%C3%AAn+t%E1%BA%A1o+t%C3%A0i+kho%E1%BA%A3n+m%E1%BB%9Bi+%3AD&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDEwMDFqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8'
                  target='blank'
                >
                  Quên mật khẩu?
                </Typography.Link>
              </Row>
              <Row>
                <Button type='primary' htmlType='submit' className='min-w-full min-h-9 mt-5 mx-auto'>
                  Đăng nhập
                </Button>
              </Row>
            </Form>
            <Typography.Text className='text-xs'>
              Bạn chưa có tài khoản?
              <Link
                onClick={() => {
                  dispatch(setShowLoginModal(false));
                  dispatch(setShowRegisterModal(true));
                }}
                className='text-xs ml-1'
              >
                Đăng ký
              </Link>
            </Typography.Text>
          </Space>
        </Modal>
      )}
    </>
  );
};

export default Login;
