import React from "react";
import TagPromo from "../../components/TagPromo";

const Freeship = () => {
  return (
    <>
      <TagPromo title='Khuyến mãi' />
      <div class='grid gap-2 px-4 md:px-0'>
        {/* <div class='grid grid-flow-col items-start justify-start gap-1.5'>
          <div class='relative h-6 w-6 shrink-0'>
            <img
              class='object-cover'
              src='/gift.png'
              alt=''
              width='500'
              height='500'
              loading='lazy'
              sizes='(max-width: 768px) 3rem, 3rem'
            />
          </div>
          <p class='text-[13px] leading-[20px] first-letter:uppercase'>
            <span class='inline pe-1 font-semibold'>Mua 1 tặng 1 - P20078 (01-31/05)</span>
          </p>
        </div> */}
        <div class='grid grid-flow-col items-start justify-start gap-1.5'>
          <div class='relative h-6 w-6 shrink-0'>
            <img class='object-cover' src='/gift.png' alt='' width='500' height='500' loading='lazy' sizes='(max-width: 768px) 3rem, 3rem' />
          </div>
          <p class='text-[13px] leading-[20px] first-letter:uppercase'>
            <span class='inline pe-1 font-semibold'>Giao Nhanh 2H bởi Ahamove</span>Giao hàng tích tắc trong vòng 2 tiếng cho sản phẩm này
          </p>
        </div>
      </div>
    </>
  );
};

export default Freeship;
