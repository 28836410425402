import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Pagination, Spin, Tag, Typography, Button, Space } from "antd";
import { get, map, orderBy } from "lodash";
import { ClearOutlined, DownSquareOutlined, UpSquareOutlined, FundOutlined, SortAscendingOutlined } from "@ant-design/icons";

import Product from "../Product";
import { getAllProduct } from "../../services/productServices";
import { Link } from "react-router-dom";

const ProductList = (props) => {
  const params = get(props, "params", null);
  const placement = get(props, "placement", "home");
  const [pagination, setPagination] = useState({ page: props?.page, size: props?.size });
  const [sort, setSort] = useState(null);
  const [active, setActive] = useState(null);

  const {
    data: productList,
    isFetching,
    refetch,
  } = useQuery("getAllProductList", {
    queryFn: async () => {
      const data = await getAllProduct({ params: { ...params, ...pagination } });
      return data;
    },
    optimisticResults: true,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const products = useMemo(() => {
    if (!isFetching && productList && productList.data.length >= 0) {
      switch (sort) {
        case "name":
          return {
            ...productList,
            data: orderBy(productList?.data, ["name"], ["asc"]),
          };
        case "sold":
          return {
            ...productList,
            data: orderBy(productList?.data, ["sold"], ["desc"]),
          };
        case "low":
          return {
            ...productList,
            data: orderBy(productList?.data, ["discountPrice"], ["desc"]),
          };
        case "hight":
          return {
            ...productList,
            data: orderBy(productList?.data, ["discountPrice"], ["asc"]),
          };
        default:
          return productList;
      }
    }
  }, [isFetching, productList, sort]);

  const handleOnFilter = (value) => {
    setActive(value);
    setSort(value);
  };

  return (
    <div className='flex flex-col min-h-screen'>
      {placement === "search" ? (
        <>
          <div className='wide mx-auto text-center flex flex-col'>
            <div className='flex justify-between items-center px-4 mb-4'>
              <Typography>Danh sách sản phẩm</Typography>
              <Space className='ml-auto'>
                <Typography>Sắp xếp theo</Typography>
                <Button type={active === "name" ? "primary" : "dashed"} icon={<SortAscendingOutlined />} onClick={() => handleOnFilter("name")}>
                  Tên sản phẩm
                </Button>
                <Button type={active === "sold" ? "primary" : "dashed"} icon={<FundOutlined />} onClick={() => handleOnFilter("sold")}>
                  Bán chạy
                </Button>
                <Button type={active === "hight" ? "primary" : "dashed"} icon={<UpSquareOutlined />} onClick={() => handleOnFilter("hight")}>
                  Giá tăng
                </Button>
                <Button type={active === "low" ? "primary" : "dashed"} icon={<DownSquareOutlined />} onClick={() => handleOnFilter("low")}>
                  Giá giảm
                </Button>
                <Button type='default' icon={<ClearOutlined />} onClick={() => handleOnFilter(null)}>
                  Xóa
                </Button>
              </Space>
            </div>
            <div className={"mt-0 grid lg:grid-cols-4 px-3 md:grid-cols-3 grid-cols-2 gap-x-3 gap-y-3"}>
              {map(products?.data ?? [], (product) => (
                <Product product={product} />
              ))}
              <Spin spinning={isFetching} fullscreen tip='Đang tải dữ liệu...' />
            </div>
          </div>
          <Pagination
            className='mt-auto pt-8'
            total={products?.total ?? 0}
            onChange={(page, size) => setPagination({ page, size })}
            showTotal={(total) => `Tổng ${total} sản phẩm`}
            showTitle
            pageSize={pagination?.size}
            showSizeChanger
            pageSizeOptions={[12, 24, 48, 60, 72]}
          />
        </>
      ) : (
        <div className='wide mx-auto text-center flex flex-col lg:px-0 px-3'>
          <div className={"mt-8 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-x-3 gap-y-3"}>
            {map(products?.data ?? [], (product) => (
              <Product product={product} />
            ))}
            <Spin spinning={isFetching} fullscreen tip='Đang tải dữ liệu...' />
          </div>
          <Link to={"/tim-kiem"} className='text-center mt-10'>
            <Tag className='text-gray-500 font-medium px-2 py-1 rounded-sm'>XEM THÊM SẢN PHẨM</Tag>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProductList;
