import { Image } from "antd";
import { find } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

const Promotion = () => {
  const { banners } = useSelector((state) => state?.imageReducer);

  return (
    <div className='wide mx-auto flex justify-between items-center gap-[15px] lg:px-0 px-3'>
      <div
        className='lg:hidden w-[full]'
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      >
        <Image className='rounded-lg w-full' src={find(banners, (item) => item?.name === "banner4")?.url || "https://pic.pnnet.dev/600x600"}></Image>
      </div>
      <div
        className='lg:w-[400px] lg:bg-[#ccc] lg:h-[400px] lg:rounded-lg lg:block hidden'
        style={{
          backgroundImage: `url(${find(banners, (item) => item?.name === "banner4")?.url || "url(https://pic.pnnet.dev/600x600"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      >
        <Image className='rounded-lg w-full' src={find(banners, (item) => item?.name === "banner4")?.url || "https://pic.pnnet.dev/600x600"}></Image>
      </div>
      <div
        className='lg:flex-1 lg:bg-[#ccc] lg:h-[400px] lg:rounded-lg lg:block hidden'
        style={{
          backgroundImage: `url(${find(banners, (item) => item?.name === "banner3")?.url || "url(https://pic.pnnet.dev/1245x600"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      >
        <Image className='rounded-lg w-full' src={find(banners, (item) => item?.name === "banner3")?.url || "https://pic.pnnet.dev/1245x600"}></Image>
      </div>
    </div>
  );
};

export default Promotion;
