import React, { Fragment, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import { Breadcrumb, Spin, Tag, Tooltip, Typography } from "antd";
import { useQuery } from "react-query";
import { NumericFormat } from "react-number-format";
import { HomeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { setRecentProduct } from "../redux/reducer/recentProductReducer";
import { getAllProduct } from "../services/productServices";
import ProductDetail from "../components/ProductDetail";
import ProductSlider from "../components/ProductSlider";
import AddToCart from "../components/AddToCart";
import Preview from "../components/Preview";
import TagPromo from "../components/TagPromo";

import { getPackingList } from "../utils";
import { getAllCategory } from "../services/categoryServices";
import { find } from "lodash";

const ProductDetailPage = () => {
  const { category, url } = useParams();
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const { recentProduct } = useSelector((state) => state?.recentProductReducer);

  useEffect(() => {
    if (product) {
      dispatch(setRecentProduct(product));
      document.title = product?.name ?? "";
    }
    window.scrollTo({ top: 0, behavior: "instant" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const { data, isFetching, refetch } = useQuery("getAllProduct", {
    queryFn: async () => {
      const response = await getAllProduct({ params: { category, url } });
      return response?.data?.[0];
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: categoryList, isFetching: fetchingCategory } = useQuery("getAllCategories", {
    queryFn: getAllCategory,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isFetching) {
      setProduct(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, category, url]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, url]);

  const categories = useMemo(() => {
    if (!fetchingCategory && categoryList && categoryList.length >= 0) return categoryList;
  }, [categoryList, fetchingCategory]);

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            href: `/danh-muc/${product?.category}`,
            title: <span>{find(categories, (item) => item?.slug === product?.category)?.name}</span>,
          },
          {
            title: (
              <Tooltip className='max-w-96 line-clamp-1' title={product?.name}>
                {product?.name || "Chi tiết sản phẩm"}
              </Tooltip>
            ),
          },
        ]}
      />
      <div className='wide mx-auto bg-white py-6 px-4 rounded-xl flex gap-5'>
        <div className='w-[75%] h-full'>
          <div className='flex gap-5'>
            <div className='w-[50%] max-w-[420px]'>
              <Preview product={product} />
            </div>
            <div className='w-[50%] flex-1'>
              <ProductDetail product={product} />
            </div>
          </div>
          <div className='flex flex-col gap-3 overflow-hidden'>
            <TagPromo title='Chi tiết sản phẩm' />
            <div dangerouslySetInnerHTML={{ __html: product?.description?.replace(/<li/g, "<li style='margin-left: 12px;'") }} />
          </div>
        </div>
        <div className='w-[25%] h-[375px] rounded-md border border-dashed sticky top-44 p-4'>
          <p className='text-base text-gray-500'>Sản phẩm 100% nhập khẩu chính hãng từ Úc</p>
          <div class='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed my-5'></div>
          <div className='flex items-center justify-center'>
            <div className='flex flex-col'>
              {product?.discountPercent > 0 &&  <Tag color="yellow" className="max-w-fit">{`Giảm giá ${product?.discountPercent}%`}</Tag>}
              <NumericFormat
                className='text-[16px] text-gray-400 line-through'
                value={(!product?.discountPrice && product?.discountPercent === 0) ? null : product?.salePrice}
                displayType='text'
                thousandSeparator='.'
                decimalSeparator=','
                suffix=' ₫'
              />
              <NumericFormat
                className='font-bold text-[24px] text-primary !text-center block text-nowrap'
                value={product?.discountPrice ?? product?.salePrice}
                displayType='text'
                thousandSeparator='.'
                decimalSeparator=','
                suffix=' ₫'
              />
            </div>
            <span className='font-medium text-[16px] text-primary ml-3 self-end'>{`/ ${getPackingList(Number(product?.packing))?.label ?? "Sản phẩm"}`}</span>
          </div>
          <div class='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed my-5'></div>
          <AddToCart product={product} />
        </div>
      </div>
      <ProductSlider sliderPerView={5} title='Sản phẩm tương tự' className='rounded-lg' params={{ category }} buttonElm='same' />
      <ProductSlider sliderPerView={5} title='Sản phẩm đã xem' className='rounded-lg' products={recentProduct} buttonElm='recent' />
      <Spin spinning={isFetching} fullscreen tip='Đang tải dữ liệu...'></Spin>
    </Fragment>
  );
};

export default ProductDetailPage;
