import React from "react";

const TagPromo = ({ title }) => {
  return (
    <>
      <div class='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed my-5'></div>
      <h4 class='max-w-[200px] text-[16px] leading-[24px] relative whitespace-nowrap bg-primary px-4 font-semibold text-white'>
        {title}
        <span class='absolute right-0 top-0 block h-0 w-0 border-b-[12px] border-r-[8px] border-t-[12px] border-white border-b-transparent border-t-transparent'></span>
      </h4>
    </>
  );
};

export default TagPromo;
