import { createSlice } from "@reduxjs/toolkit";

export const imageSlide = createSlice({
  name: "images",
  initialState: {
    banners: [],
    sliders: [],
  },
  reducers: {
    setBanners: (state, action) => {
      state.banners = action.payload;
    },
    setSliders: (state, action) => {
      state.sliders = action.payload;
    },
  },
});

export const { setBanners, setSliders } = imageSlide.actions;

export default imageSlide.reducer;
