import React, { useEffect } from "react";

import Brands from "../components/Brands";
import FlashSale from "../components/FlashSale";
import Banner from "../components/Banner";
import Categories from "../components/Categories";
import Promotion from "../components/Promotion";
import ProductList from "../components/ProductList";

const HomePage = () => {
  useEffect(() => {
    document.title = "Healthymall.vn - Thực phẩm chức năng chính hãng từ Úc";
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <div>
      <Banner />
      <Categories />
      <FlashSale />
      <Brands />
      <Promotion />
      <ProductList page={1} size={25} />
    </div>
  );
};

export default HomePage;
